import React, { useContext } from "react";
import "./style.css";
import { CommonContext } from "../../../../App";
import LogoText from "../../../ui/logoText";
import CircleLoading from "../../../ui/circleLoading";

const AdminPublicLayout = (props) => {
  const { Component } = props;
  const { isLoading, actionDetails } = useContext(CommonContext);

  return (
    <div className="publicLayout">
      <div className="publicLayoutLogo">
        <LogoText screen="publicLogo" />
      </div>
      <div className="publicLayoutComponent">
        <Component {...props} />
      </div>

      {!Object.keys(actionDetails).length && isLoading ? (
        <div className="publicLayoutLoading">
          <CircleLoading /> <span>Loading...</span>
        </div>
      ) : null}
    </div>
  );
};

export default AdminPublicLayout;
