import React from "react";
import "./style.css";

const LogoText = ({ screen = "privateLogo" }) => {
  return (
    <div className={screen}>
      <img src={`${process.env.PUBLIC_URL}/assets/logo.png`} alt="rrr-logo" />
    </div>
  );
};

export default LogoText;
