import moment from "moment";
import React from "react";

const boolValues = [];
const integerValues = [
  "completed_task",
  "daily_income",
  "reward_amount",
  "total",
];
const dateValues = ["date"];
const TableBody = ({ keys, data, tableName }) => {
  const getValuesProper = (val, key) => {
    let str = val;

    if (boolValues.includes(key)) {
      str = val ? "Yes" : "No";
    }
    if (dateValues.includes(key)) {
      const date = moment(val).format("DD-MM-YYYY");
      str = date !== "Invalid date" ? date : "-";
    }
    if (integerValues.includes(key)) {
      str = !val || val === "-" ? "0" : val.toString();
    }

    return str;
  };

  return (
    <tbody>
      {data.map((item, itemInd) => {
        return (
          <tr key={`${tableName.replace(/\s/g, "-")}-data-row-${itemInd + 1}`}>
            {keys.map((key, keyInd) => {
              const value = getValuesProper(item?.[key], key) || "-";
              return (
                <td
                  key={`${tableName.replace(/\s/g, "-")}-data-key-${
                    keyInd + 1
                  }`}
                >
                  {value}
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
