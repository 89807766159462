import React, { useContext } from "react";
import { CommonContext } from "../../../App";
import { amountOptions, checkNumberValue } from "../../../helper";
import { putCall } from "../../../api/fetchConfig";
import { URL } from "../../../api/urls";
import { toast } from "react-toastify";
import { useFormik } from "formik";

const validate = (values) => {
  const errors = {};

  if (!values.amount) {
    errors.amount = "Amount is required";
  }

  if (!values.user_mobile) {
    errors.user_mobile = "User mobile is required";
  }

  return errors;
};

const TurntableForm = ({ fetchList }) => {
  const { setIsLoading, actionDetails, setActionDetails } =
    useContext(CommonContext);
  // const [userList, setUserList] = useState([]);

  // useEffect(() => {
  //   fetchAdminTurntableUserList();
  // }, []);

  // const fetchAdminTurntableUserList = async () => {
  //   try {
  //     const response = await getCall(URL.GET_ADMIN_TURNTABLES_USERS);
  //     if (response && response.status === 200) {
  //       const res = await response.json();
  //       const { status_code, data, error } = res;
  //       if (status_code === 200) {
  //         setUserList(data);
  //       } else {
  //         toast.error(error);
  //       }
  //     }
  //   } catch (error) {
  //     toast.error("Something went wrong. Contact your administrator");
  //     console.error(error);
  //   }
  // };

  const handleLuckFormSubmit = async (values) => {
    const body = {
      ...values,
      amount: checkNumberValue(values, "amount"),
    };

    try {
      setIsLoading(true);
      const response = await putCall(URL.GET_ADMIN_TURNTABLES, body);
      if (response && response.status === 200) {
        const res = await response.json();

        if (!res || Object.keys(res).length === 0) {
          throw new Error("Empty response from server");
        }

        const { status_code, detail, error } = res;
        if (status_code === 200) {
          fetchList();
          toast.success(detail);
          handleModalClose();
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Contact your administrator");
      console.error(error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: actionDetails?.data,
    onSubmit: handleLuckFormSubmit,
    validate,
  });

  const handleModalClose = () => setActionDetails({});

  const handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <div className="planFormMain">
      <form onSubmit={formik.handleSubmit}>
        {renderLuckFormFields.map((field, index) => {
          return field.type === "select" ? (
            <div
              className="planInputWrapper"
              key={`luck-form-field-${field.name}-${index + 1}`}
            >
              <div>
                <label htmlFor={field.name}>{field.label}</label>
                <select
                  onChange={formik.handleChange}
                  value={formik.values[field.name]}
                  name={field.name}
                >
                  {/* <option value="">None</option> */}
                  <option disabled selected value="">
                    -- select an option --
                  </option>
                  {amountOptions.map((item) => (
                    <option
                      key={`amount-option-${item.value}`}
                      value={item.value}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              <span className="errorText">{formik.errors[field.name]}</span>
            </div>
          ) : (
            <div
              className="planInputWrapper"
              key={`luck-form-field-${field.name}-${index + 1}`}
            >
              <div>
                <label htmlFor={field.name}>{field.label}</label>
                <input
                  onKeyPress={handleKeyPress}
                  maxLength="10"
                  value={formik.values[field.name]}
                  onChange={formik.handleChange}
                  type={field.type}
                  name={field.name}
                />
              </div>
              <span className="errorText">{formik.errors[field.name]}</span>
            </div>
          );
        })}

        <div className="btnGroup">
          <button onClick={handleModalClose} type="button">
            Close
          </button>
          <button type="submit">Update</button>
        </div>
      </form>
    </div>
  );
};

export default TurntableForm;

const renderLuckFormFields = [
  {
    label: "User Mobile",
    name: "user_mobile",
    type: "text",
  },
  {
    label: "Amount",
    name: "amount",
    type: "select",
  },
];
