export const profitDetailTable = {
  plan_details: [
    {
      level: "Intern",
      security_deposit: "0",
      task_quantity: "5",
      price_unit: "10",
      daily_wages: "50",
      thirty_days_income: "-",
      three_sixty_days_income: "-",
    },
    {
      level: "J1",
      security_deposit: "1800",
      task_quantity: "5",
      price_unit: "12",
      daily_wages: "60",
      thirty_days_income: "1800",
      three_sixty_days_income: "21600",
    },
    {
      level: "J2",
      security_deposit: "4800",
      task_quantity: "10",
      price_unit: "16",
      daily_wages: "160",
      thirty_days_income: "4800",
      three_sixty_days_income: "57600",
    },
    {
      level: "J3",
      security_deposit: "15000",
      task_quantity: "15",
      price_unit: "36",
      daily_wages: "540",
      thirty_days_income: "16200",
      three_sixty_days_income: "194400",
    },
    {
      level: "J4",
      security_deposit: "45000",
      task_quantity: "30",
      price_unit: "54",
      daily_wages: "1620",
      thirty_days_income: "48600",
      three_sixty_days_income: "583200",
    },
    {
      level: "J5",
      security_deposit: "120000",
      task_quantity: "50",
      price_unit: "93",
      daily_wages: "4650",
      thirty_days_income: "139500",
      three_sixty_days_income: "1674000",
    },
    {
      level: "J6",
      security_deposit: "300000",
      task_quantity: "75",
      price_unit: "155",
      daily_wages: "11625",
      thirty_days_income: "348750",
      three_sixty_days_income: "4185000",
    },
    {
      level: "J7",
      security_deposit: "715000",
      task_quantity: "150",
      price_unit: "200",
      daily_wages: "30000",
      thirty_days_income: "900000",
      three_sixty_days_income: "10800000",
    },
    {
      level: "J8",
      security_deposit: "1420000",
      task_quantity: "220",
      price_unit: "275",
      daily_wages: "60500",
      thirty_days_income: "1815000",
      three_sixty_days_income: "21780000",
    },
  ],
  referral_details: [
    {
      level: "J1",
      third_level_income_ratio: "10% - 4% - 2%",
      a_level_invitation_reward: "180",
      b_level_invitation_reward: "72",
      c_level_invitation_reward: "36",
    },
    {
      level: "J2",
      third_level_income_ratio: "10% - 4% - 2%",
      a_level_invitation_reward: "480",
      b_level_invitation_reward: "192",
      c_level_invitation_reward: "96",
    },
    {
      level: "J3",
      third_level_income_ratio: "10% - 4% - 2%",
      a_level_invitation_reward: "1500",
      b_level_invitation_reward: "600",
      c_level_invitation_reward: "300",
    },
    {
      level: "J4",
      third_level_income_ratio: "10% - 4% - 2%",
      a_level_invitation_reward: "4500",
      b_level_invitation_reward: "1800",
      c_level_invitation_reward: "900",
    },
    {
      level: "J5",
      third_level_income_ratio: "10% - 4% - 2%",
      a_level_invitation_reward: "12000",
      b_level_invitation_reward: "4800",
      c_level_invitation_reward: "2400",
    },
    {
      level: "J6",
      third_level_income_ratio: "10% - 4% - 2%",
      a_level_invitation_reward: "30000",
      b_level_invitation_reward: "12000",
      c_level_invitation_reward: "6000",
    },
    {
      level: "J7",
      third_level_income_ratio: "10% - 4% - 2%",
      a_level_invitation_reward: "71500",
      b_level_invitation_reward: "28600",
      c_level_invitation_reward: "14300",
    },
    {
      level: "J8",
      third_level_income_ratio: "10% - 4% - 2%",
      a_level_invitation_reward: "142000",
      b_level_invitation_reward: "56800",
      c_level_invitation_reward: "28400",
    },
  ],
  income_details: [
    {
      level: "J1",
      third_level_income_ratio: "5% - 3% - 1%",
      a_level_mission_earnings: "3",
      b_level_mission_earnings: "1.8",
      c_level_mission_earnings: "0.6",
    },
    {
      level: "J2",
      third_level_income_ratio: "5% - 3% - 1%",
      a_level_mission_earnings: "8",
      b_level_mission_earnings: "4.8",
      c_level_mission_earnings: "1.6",
    },
    {
      level: "J3",
      third_level_income_ratio: "5% - 3% - 1%",
      a_level_mission_earnings: "27",
      b_level_mission_earnings: "16.2",
      c_level_mission_earnings: "5.4",
    },
    {
      level: "J4",
      third_level_income_ratio: "5% - 3% - 1%",
      a_level_mission_earnings: "81",
      b_level_mission_earnings: "48.6",
      c_level_mission_earnings: "16.2",
    },
    {
      level: "J5",
      third_level_income_ratio: "5% - 3% - 1%",
      a_level_mission_earnings: "232.5",
      b_level_mission_earnings: "139.5",
      c_level_mission_earnings: "46.5",
    },
    {
      level: "J6",
      third_level_income_ratio: "5% - 3% - 1%",
      a_level_mission_earnings: "581.25",
      b_level_mission_earnings: "348.75",
      c_level_mission_earnings: "116.25",
    },
    {
      level: "J7",
      third_level_income_ratio: "5% - 3% - 1%",
      a_level_mission_earnings: "1500",
      b_level_mission_earnings: "900",
      c_level_mission_earnings: "300",
    },
    {
      level: "J8",
      third_level_income_ratio: "5% - 3% - 1%",
      a_level_mission_earnings: "3025",
      b_level_mission_earnings: "1815",
      c_level_mission_earnings: "605",
    },
  ],
};

const InternProfitDetail =
  "Interns have a four-day internship period and do not need to pay a deposit during the internship. Five tasks per day, each task carns 10 INR, earns 50 INR per day, and earns a total of 200 INR during the four-day internship.";

const J1ProfitDetail =
  "Pay a deposit of 1800 INR, 5 tasks per day, each task earns 12 INR, earns 60 INR per day, earns 1800 INR per month, and earns 21600 INR per year. The J1 level task period is automatically renewed after one year, which is equivalent to a permanent subscription. The security deposit will be returned to your account.";

const J2ProfitDetail =
  "Pay a deposit of 4800 INR, 10 tasks per day, each task earns 16 INR, earns 160 INR per day, earns 4800 INR per month, and earns 57600 INR per year. The J2 level task period is automatically renewed after one year, which is equivalent to a permanent subscription. The security deposit will be returned to your account.";

const J3ProfitDetail =
  "Pay a deposit of 15000 INR, 15 tasks per day, each task earns 36 INR, earns 540 INR per day, earns 16200 INR per month, and earns 194400 INR per year. The J3 level task period is automatically renewed after one year, which is equivalent to a permanent subscription. The security deposit will be returned to your account.";

const J4ProfitDetail =
  "Pay a deposit of 45000 INR, 30 tasks per day, each task earns 54 INR, earns 1620 INR per day, earms 48600 INR per month, and earns 583200 INR per year. The J4 level task period is automatically renewed after one year, which is equivalent to a permanent subscription. The security deposit will be returned to your account.";

const J5ProfitDetail =
  "Pay a deposit of 120000 INR, 50 tasks per day, each task earns 93 INR, earns 4650 INR per day, earns 139500 INR per month, and earns 1674000 INR per year. The J5 level task period is automatically renewed after one year, which is equivalent to a permanent subscrption. The security deposit will be returned to your account.";

const J6ProfitDetail =
  "Pay a deposit of 300000 INR, 75 tasks per day, each task earns 155 INR, earns 11625 INR per day, earns 348750 INR per month, and earns 4185000 INR per year. The J6 level task period is automatically renewed after one year, which is equivalent to a permanent subscrption. The security deposit will be returned to your account.";

const J7ProfitDetail =
  "Pay a deposit of 715000 INR, 150 tasks per day, each task earns 200 INR, carms 30000 INR per day, earms 900000 INR per month, and earns 10800000 INR per year. The J7 level task perlod is automatically renewed after one year, which is equivalent to a permanent subscription .The security deposit will be returned to your account.";

const J8ProfitDetail =
  "Pay a deposit of 1420000 INR, 220 tasks per day, each task earns 275 INR, eans 60500 INR per day, eams 1815000 INR per month, and earns 21780000 INR per year. The J8 level task period is automatically renewed after one year, which is equivalent to a permanent subscription The security deposit will be returned to your account.";

const referenceDetail1 =
  "Reference income: For example, if you belong to J1 and recommend your subordinate A to become J1, you can earn invitation rewards of 180 INR. If your A-level employee recommends his subordinate B to become J1, you can get an income of 72 INR. If your B-level employee recommends his subordinate C to become J1, you can get 36 INR income.";

// const referenceDetail2 = "If you are a J1-level employee and the subordinate you invite becomes a J2-level employee, then you can only get the JI invitation reward (216INR). If you are a J3-level employee and the subordinate you invite upgrades to J3, you will get (1800INR)."

// const referenceDetail3 = "You can only get the invitation reward of your same level. If your subordinate's level is higher than yours, you can only get your current level invitation rewards. When you have better business opportunities, you can increase your staff level, which will greatly increase your income."

const incomeDetail1 =
  "The daily task bonus for A-level subordinates is 5% of the daily income. J3's daily income is 540INR. If you have 10 J3 A-level subordinates, the daily task bonus you can get is 540*10*5% - 270 INR.";

const incomeDetail2 =
  "The daily task bonus for B-level subordinates is 3% of the daily income. J3's daily income is 540INR. If you have 10 J3 B-level subordinates, the the daily task bonus you can get is 540*10*3% - 162 INR.";

const incomeDetail3 =
  "The daily task bonus for C-level subordinates is 1% of the daily income. J3's daily income is 540INR. If you have 10 J3 C-level subordinates, the daily task bonus you can get is 540*10*1% - 54 INR.";

const incomeDetail4 =
  "We look forward to having you and your team join us. RGA will lead you to a bright and great future. The future holds endless possibilities and we will be together forever.";

export const profitDetails = [
  {
    imgName: "plan-details-table",
    name: "Plan Details",
    tableDetails: {
      name: "plan_details",
      columns: [
        "Level",
        "Security Deposit",
        "Task Quantity",
        "Price Unit",
        "Daily Wages",
        "30 Days Income",
        "360 Days Income",
      ],
      keys: [
        "level",
        "security_deposit",
        "task_quantity",
        "price_unit",
        "daily_wages",
        "thirty_days_income",
        "three_sixty_days_income",
      ],
    },
    contents: [
      { label: "", value: InternProfitDetail },
      { label: "Job 1", value: J1ProfitDetail },
      { label: "Job 2", value: J2ProfitDetail },
      { label: "Job 3", value: J3ProfitDetail },
      { label: "Job 4", value: J4ProfitDetail },
      { label: "Job 5", value: J5ProfitDetail },
      { label: "Job 6", value: J6ProfitDetail },
      { label: "Job 7", value: J7ProfitDetail },
      { label: "Job 8", value: J8ProfitDetail },
    ],
  },
  {
    imgName: "referral-table",
    name: "Referral Details",
    tableDetails: {
      name: "referral_details",
      columns: [
        "Level",
        "Third - Level Income Ratio",
        "A - Level Invitation Reward",
        "B - Level Invitation Reward",
        "C - Level Invitation Reward",
      ],
      keys: [
        "level",
        "third_level_income_ratio",
        "a_level_invitation_reward",
        "b_level_invitation_reward",
        "c_level_invitation_reward",
      ],
    },
    contents: [{ label: "", value: referenceDetail1 }],
  },
  {
    imgName: "task-reward-table",
    name: "Income Details",
    tableDetails: {
      name: "income_details",
      columns: [
        "Level",
        "Third - Level Income Ratio",
        "A - Level Mission Earnings",
        "B - Level Mission Earnings",
        "C - Level Mission Earnings",
      ],
      keys: [
        "level",
        "third_level_income_ratio",
        "a_level_mission_earnings",
        "b_level_mission_earnings",
        "c_level_mission_earnings",
      ],
    },
    contents: [
      { label: "", value: incomeDetail1 },
      { label: "", value: incomeDetail2 },
      { label: "", value: incomeDetail3 },
      { label: "", value: incomeDetail4 },
    ],
  },
];
