import React, { useContext } from "react";
import "./style.css";
import { useFormik } from "formik";
import { CommonContext } from "../../../App";
import { putCall } from "../../../api/fetchConfig";
import { URL } from "../../../api/urls";
import { toast } from "react-toastify";

const validate = (values) => {
  const errors = {};

  if (!values.plan_level) {
    errors.plan_level = "Plan Level is required";
  }

  return errors;
};

const checkNumberValue = (values, key) => {
  return values?.[key] ? parseInt(values[key]) : 0;
};
const PlanForm = ({ fetchPlanList }) => {
  const { setIsLoading, actionDetails, setActionDetails } =
    useContext(CommonContext);

  const handleModalClose = () => setActionDetails({});

  const handlePlanFormSubmit = async (values) => {
    const body = {
      ...values,
      task_per_day: checkNumberValue(values, "task_per_day"),
      deposit: checkNumberValue(values, "deposit"),
      amount_per_task: checkNumberValue(values, "amount_per_task"),
      validity: checkNumberValue(values, "validity"),
    };

    try {
      setIsLoading(true);
      const response = await putCall(URL.GET_PLANS, body);
      if (response && response.status === 200) {
        const res = await response.json();

        if (!res || Object.keys(res).length === 0) {
          throw new Error("Empty response from server");
        }

        const { status_code, detail, error } = res;
        if (status_code === 200) {
          fetchPlanList();
          toast.success(detail);
          handleModalClose();
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Contact your administrator");
      console.error(error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: actionDetails?.data,
    onSubmit: handlePlanFormSubmit,
    validate,
  });

  const inputOnchange = (e, name) => {
    const { value, checked } = e.target;

    formik.setFieldValue(name, name === "is_active" ? checked : value);
  };

  return (
    <div className="planFormMain">
      <form onSubmit={formik.handleSubmit}>
        {renderFormFields.map((field, index) => {
          return field.type === "checkbox" ? (
            <div
              className="planInputWrapperCheckbox"
              key={`plan-form-field-${field.name}-${index + 1}`}
            >
              <div>
                <label htmlFor={field.name}>{field.label}</label>
                <input
                  name={field.name}
                  onChange={(e) => inputOnchange(e, field.name)}
                  value={formik.values[field.name]}
                  checked={formik.values[field.name]}
                  id={field.name}
                  type={field.type}
                />
              </div>
              <span className="errorText">{formik.errors[field.name]}</span>
            </div>
          ) : (
            <div
              className="planInputWrapper"
              key={`plan-form-field-${field.name}-${index + 1}`}
            >
              <div>
                <label htmlFor={field.name}>{field.label}</label>
                <input
                  name={field.name}
                  onChange={(e) => inputOnchange(e, field.name)}
                  value={formik.values[field.name]}
                  id={field.name}
                  type={field.type}
                  min={0}
                />
              </div>
              <span className="errorText">{formik.errors[field.name]}</span>
            </div>
          );
        })}

        <div className="btnGroup">
          <button onClick={handleModalClose} type="button">
            Close
          </button>
          <button type="submit">Update</button>
        </div>
      </form>
    </div>
  );
};

export default PlanForm;

const renderFormFields = [
  {
    label: "Plan Level",
    name: "plan_level",
    type: "text",
  },
  {
    label: "Deposit",
    name: "deposit",
    type: "number",
  },
  {
    label: "Amount Per Task",
    name: "amount_per_task",
    type: "number",
  },
  {
    label: "Task Per Day",
    name: "task_per_day",
    type: "number",
  },
  {
    label: "Is Active",
    name: "is_active",
    type: "checkbox",
  },
  {
    label: "Validity (Days)",
    name: "validity",
    type: "number",
  },
];
