import React, { useContext } from "react";
import "./style.css";
import LogoText from "../../ui/logoText";
import CircleLoading from "../../ui/circleLoading";
import { CommonContext } from "../../../App";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "../../ui/modal";
import { clearLocalStorage } from "../../../helper";

const PrivateLayout = (props) => {
  const { Component } = props;
  const {
    isLoading,
    userDetails,
    setIsLoading,
    actionDetails,
    setActionDetails,
  } = useContext(CommonContext);
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const isLogoutScreenEnable =
    actionDetails?.screenName === "logout" && actionDetails?.isOpen;
  const splitPathName = pathname.split("/")[1];

  const handleBackButton = () => {
    navigate(-1);
  };

  const handleFooterIconButton = (path) => {
    navigate(path);
  };

  const handleLogoutBtn = () => {
    setActionDetails({
      isOpen: true,
      screenName: "logout",
    });
  };

  const handleLogoutModalClose = () => setActionDetails({});

  const handleLogoutYes = () => {
    setIsLoading(true);
    setTimeout(() => {
      clearLocalStorage();
      setIsLoading(false);
      handleLogoutModalClose();
      navigate("/login");
    }, 500);
  };

  return (
    <>
      <div className="privateLayout">
        <div className="privateLayoutHeader">
          <div className="privateLayoutHeaderStart">
            {pathname !== "/" ? (
              <button type="button" onClick={handleBackButton}>
                <i className="fa-solid fa-arrow-left"></i>
              </button>
            ) : null}
            <Link to="/">
              <LogoText />
            </Link>
          </div>
          <button onClick={handleLogoutBtn} type="button">
            <i className="fa-solid fa-right-from-bracket"></i>
          </button>
        </div>
        <div className="privateLayoutComponent">
          <Component {...props} />
        </div>
        <div className="privateLayoutFooter">
          {footerNavs.map((item, index) => {
            const pathName =
              item.name === "profileNav"
                ? item.path + `/${userDetails?.user_id}`
                : item.path;

            return (
              <button
                onClick={() => {
                  handleFooterIconButton(pathName);
                }}
                className={`${
                  item.path.split("/")[1] === splitPathName ? "active" : ""
                }`}
                key={`footer-nav-item-${item.name}-${index + 1}`}
              >
                <i className={`fa-solid fa-${item.icon}`}></i>
                {item.label}
              </button>
            );
          })}
        </div>
        {!Object.keys(actionDetails).length && isLoading ? (
          <div className="privateLayoutLoading">
            <CircleLoading /> <span>Loading...</span>
          </div>
        ) : null}
      </div>
      <Modal isOpen={isLogoutScreenEnable}>
        <div className="logoutModalContent">
          <p>Are you sure want to logout?</p>
        </div>
        <div className="logoutModalGroupBtn">
          <button onClick={handleLogoutModalClose} type="button">
            No
          </button>
          <button onClick={handleLogoutYes} type="button">
            Yes
          </button>
        </div>
      </Modal>
    </>
  );
};

export default PrivateLayout;

const footerNavs = [
  {
    label: "Home",
    name: "homeNav",
    icon: "house",
    path: "/",
  },
  {
    label: "Task",
    name: "taskNav",
    icon: "list-check",
    path: "/tasks",
  },
  {
    label: "VIP",
    name: "vipNav",
    icon: "crown",
    path: "/vip",
  },
  {
    label: "Profit",
    name: "profitNav",
    icon: "money-bill-trend-up",
    path: "/profit",
  },
  {
    label: "Profile",
    name: "profileNav",
    icon: "user",
    path: "/profile",
  },
];
