import React, { useContext } from "react";
import { clearLocalStorage } from "../helper";
import { CommonContext } from "../App";
import { toast } from "react-toastify";
import { postCall } from "../api/fetchConfig";
import { URL } from "../api/urls";

const SessionExpired = () => {
  const { setIsLoading } = useContext(CommonContext);
  const checkAdminRegex = /\badmin\b/g;
  const isAdmin = checkAdminRegex.test(window.location.pathname);
  const loginRedirect = isAdmin ? "/admin/login" : "/login";
  const get_refresh_token = isAdmin
    ? localStorage.getItem("AdminRefreshToken")
    : localStorage.getItem("RefreshToken");

  const imgSrc = `${process.env.PUBLIC_URL}/assets/alert.png`;

  const handleLeaveSession = () => {
    clearLocalStorage();
    sessionStorage.removeItem("SessionExpired");
    window.location.href = loginRedirect;
  };

  const handleStaySession = async () => {
    try {
      setIsLoading(true);
      const response = await postCall(
        `${URL.REFRESH_USER}?refresh_token=${get_refresh_token}`
      );
      if (response && response.status === 200) {
        const res = await response.json();

        if (!res || Object.keys(res).length === 0) {
          throw new Error("Empty response from server");
        }

        const { access_token, refresh_token } = res;

        if (access_token) {
          toast.success("Your new session has been created successfully");
          if (isAdmin) {
            localStorage.setItem("AdminAccessToken", access_token);
            localStorage.setItem("AdminRefreshToken", refresh_token);
          } else {
            localStorage.setItem("AccessToken", access_token);
            localStorage.setItem("RefreshToken", refresh_token);
          }

          sessionStorage.removeItem("SessionExpired");
        } else {
          toast.error("Authentication session expired. Please reauthenticate.");
          setTimeout(() => {
            handleLeaveSession();
          }, 5000);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Authentication session expired. Please reauthenticate.");
      setTimeout(() => {
        handleLeaveSession();
      }, 5000);
      console.error(error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "14px",
        alignItems: "center",
      }}
      className="planFormMain"
    >
      <p className="contentTitle">Session Expired</p>
      <img style={{ width: "100px" }} src={imgSrc} alt="alert.png" />
      <div style={{ width: "100%" }} className="btnGroup">
        <button onClick={handleLeaveSession} type="button">
          Leave
        </button>
        <button onClick={handleStaySession} type="button">
          Stay
        </button>
      </div>
    </div>
  );
};

export default SessionExpired;
