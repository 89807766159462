import React, { useContext } from "react";
import "./style.css";
import { CommonContext } from "../../../App";
import CircleLoading from "../circleLoading";

const GiftPopup = ({ children, isOpen = false, label = "", isHeader }) => {
  const { setActionDetails, isLoading } = useContext(CommonContext);

  const handleClose = () => {
    setActionDetails({});
  };

  return (
    <div
      style={{ display: isOpen ? "flex" : "none" }}
      className="modalMain gift"
    >
      <div className="modalMainBox gift">
        {isHeader ? (
          <div className="modalMainBoxHeader gift">
            <p>{label}</p>
            <button onClick={handleClose} type="button">
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
        ) : null}
        <div style={{ padding: 0 }} className="modalMainBoxContent gift">
          {children}

          {isLoading ? (
            <div className="modalLayoutLoading gift">
              <CircleLoading /> <span>Loading...</span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default GiftPopup;
