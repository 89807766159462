/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  englishCompanyProfiles,
  hindiCompanyProfiles,
} from "../../constant/companyProfiles";
import "./style.css";

const CompanyProfile = () => {
  // const { setIsLoading } = useContext(CommonContext);
  // const [companyProfileData, setCompanyProfileData] = useState({});
  const buildingIcon = `${process.env.PUBLIC_URL}/assets/logo.png`;

  // useEffect(() => {
  //   fetchCompanyProfile();
  // }, []);
  // const fetchCompanyProfile = async () => {
  //   try {
  //     setIsLoading(true);
  //     const response = await getCall(URL.GET_COMPANY_PROFILE);
  //     if (response && response.status === 200) {
  //       const res = await response.json();
  //       const { status_code, data, error } = res;
  //       if (status_code === 200) {
  //         setCompanyProfileData(data);
  //       } else {
  //         toast.error(error);
  //       }
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     toast.error("Something went wrong. Contact your administrator");
  //     console.error(error);
  //   }
  // };

  return (
    <div className="companyProfileMain">
      <p>Company Profile</p>
      <img src={buildingIcon} alt="company-building" />

      {hindiCompanyProfiles.map((profile, index) => {
        return (
          <span
            key={`company-profile-${index + 1}`}
            style={{
              textAlign: "justify",
            }}
          >
            {profile}
          </span>
        );
      })}

      <span className="separator"></span>

      {englishCompanyProfiles.map((profile, index) => {
        return (
          <span
            key={`company-profile-${index + 1}`}
            style={{
              textAlign: "justify",
            }}
          >
            {profile}
          </span>
        );
      })}
      {/* <span
        style={{
          textAlign:companyProfileData?.profile ? "justify" : "center",
        }}
      >
       
        {companyProfileData?.profile
          ? companyProfileData?.profile
          : "No Company Information Available"}
      </span> */}
    </div>
  );
};

export default CompanyProfile;
