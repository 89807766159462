import AdminPrivateLayout from "../components/layout/admin/private";
import AdminPublicLayout from "../components/layout/admin/public";
import PrivateLayout from "../components/layout/private";
import PublicLayout from "../components/layout/public";
import AdminReceivers from "../pages/admin/receivers";
import AdminRecharges from "../pages/admin/recharges";
import AdminHome from "../pages/admin/home";
import AdminPlans from "../pages/admin/plans";
import AdminUsers from "../pages/admin/users";
import Login from "../pages/authentication/Login";
import Register from "../pages/authentication/Register";
import RegistrationSuccess from "../pages/authentication/RegistrationSuccess";
import BumperRewards from "../pages/bumperRewards";
import CompanyProfile from "../pages/companyProfile";
import Home from "../pages/home";
import Recharges from "../pages/recharges";
import RechargeSuccess from "../pages/recharges/RechargeSuccess";
import RechargeUpload from "../pages/recharges/RechargeUpload";
import Rewards from "../pages/rewards";
import Tasks from "../pages/tasks";
import TaskDisplay from "../pages/tasks/TaskDisplay";
import UserProfile from "../pages/profile";
import BankDetails from "../pages/profile/BankDetails";
import UserWithDraw from "../pages/withdraws";
import AdminWithdraw from "../pages/admin/withdraws";
import MyTeam from "../pages/myTeam";
import Profit from "../pages/profit";
import TurntableOfLuck from "../pages/turntableOfLuck";
import VIP from "../pages/vip";
import VideoTutorial from "../pages/videoTutorial";
import AccountingRecords from "../pages/profile/AccountingRecords";
import PersonalInformation from "../pages/profile/PersonalInformation";
import DetailedInfo from "../pages/profile/DetailedInfo";
import LoginPassword from "../pages/profile/LoginPassword";
import TransactionPassword from "../pages/profile/TransactionPassword";
import VipDisplay from "../pages/vip/VipDisplay";
import VipSuccessful from "../pages/vip/VipSuccessful";
import Holidays from "../container/admin/holidays";
import AdminTurntables from "../pages/admin/turntables";
import BumperRewardAdmin from "../pages/admin/bumperReward";
import AdminTaskVideos from "../pages/admin/taskVideos";
import DailyStatement from "../pages/profile/DailyStatement";

export const routes = [
  {
    id: 1,
    path: "/register/sponsor/:sponsorId",
    name: "register",
    layout: PublicLayout,
    component: Register,
    layoutOption: "public",
  },
  {
    id: 2,
    path: "/login",
    name: "login",
    layout: PublicLayout,
    component: Login,
    layoutOption: "public",
  },
  {
    id: 3,
    path: "/",
    name: "home",
    layout: PrivateLayout,
    component: Home,
    layoutOption: "private",
  },

  {
    id: 4,
    path: "/registration/success",
    name: "registrationSuccess",
    layout: PublicLayout,
    component: RegistrationSuccess,
    layoutOption: "public",
  },

  {
    id: 5,
    path: "/rewards",
    name: "rewards",
    layout: PrivateLayout,
    component: Rewards,
    layoutOption: "private",
  },
  {
    id: 6,
    path: "/company-profile",
    name: "companyProfile",
    layout: PrivateLayout,
    component: CompanyProfile,
    layoutOption: "private",
  },
  {
    id: 7,
    path: "/admin/login",
    name: "adminLogin",
    layout: AdminPublicLayout,
    component: Login,
    layoutOption: "adminPublic",
  },
  {
    id: 8,
    path: "/admin",
    name: "adminHome",
    layout: AdminPrivateLayout,
    component: AdminHome,
    layoutOption: "adminPrivate",
  },
  {
    id: 9,
    path: "/admin/users",
    name: "adminUsers",
    layout: AdminPrivateLayout,
    component: AdminUsers,
    layoutOption: "adminPrivate",
  },
  {
    id: 10,
    path: "/admin/plans",
    name: "adminPlans",
    layout: AdminPrivateLayout,
    component: AdminPlans,
    layoutOption: "adminPrivate",
  },
  {
    id: 11,
    path: "/recharges",
    name: "userRecharges",
    layout: PrivateLayout,
    component: Recharges,
    layoutOption: "private",
  },
  {
    id: 12,
    path: "/recharges/:recharge_id",
    name: "userRecharge",
    layout: PrivateLayout,
    component: RechargeUpload,
    layoutOption: "private",
  },
  {
    id: 13,
    path: "/tasks",
    name: "tasks",
    layout: PrivateLayout,
    component: Tasks,
    layoutOption: "private",
  },
  {
    id: 14,
    path: "/tasks/display/:videoUrl",
    name: "tasksDisplay",
    layout: PrivateLayout,
    component: TaskDisplay,
    layoutOption: "private",
  },
  {
    id: 15,
    path: "/recharge/success",
    name: "rechargeSuccess",
    layout: PrivateLayout,
    component: RechargeSuccess,
    layoutOption: "private",
  },
  {
    id: 16,
    path: "/bumper-rewards",
    name: "bumperRewards",
    layout: PrivateLayout,
    component: BumperRewards,
    layoutOption: "private",
  },
  {
    id: 17,
    path: "/admin/recharges",
    name: "adminRecharges",
    layout: AdminPrivateLayout,
    component: AdminRecharges,
    layoutOption: "adminPrivate",
  },
  {
    id: 18,
    path: "/admin/receivers",
    name: "adminReceivers",
    layout: AdminPrivateLayout,
    component: AdminReceivers,
    layoutOption: "adminPrivate",
  },
  {
    id: 19,
    path: "/profile/:userId",
    name: "profile",
    layout: PrivateLayout,
    component: UserProfile,
    layoutOption: "private",
  },
  {
    id: 20,
    path: "/profile/personal-information/bank-details/:userId",
    name: "profileBankDetails",
    layout: PrivateLayout,
    component: BankDetails,
    layoutOption: "private",
  },
  {
    id: 21,
    path: "/withdraw",
    name: "userWithdraw",
    layout: PrivateLayout,
    component: UserWithDraw,
    layoutOption: "private",
  },
  {
    id: 22,
    path: "/admin/withdraw",
    name: "adminWithdraw",
    layout: AdminPrivateLayout,
    component: AdminWithdraw,
    layoutOption: "adminPrivate",
  },
  {
    id: 23,
    path: "/my-team/:userId",
    name: "homeMyTeam",
    layout: PrivateLayout,
    component: MyTeam,
    layoutOption: "private",
  },
  {
    id: 24,
    path: "/profile/my-team/:userId",
    name: "profileMyTeam",
    layout: PrivateLayout,
    component: MyTeam,
    layoutOption: "private",
  },
  {
    id: 25,
    path: "/admin/profile/:userId",
    name: "adminProfile",
    layout: AdminPrivateLayout,
    component: UserProfile,
    layoutOption: "adminPrivate",
  },
  {
    id: 26,
    path: "/admin/profile/personal-information/bank-details/:userId",
    name: "adminProfileBankDetails",
    layout: AdminPrivateLayout,
    component: BankDetails,
    layoutOption: "adminPrivate",
  },
  {
    id: 27,
    path: "/admin/profile/my-team/:userId",
    name: "adminProfileMyTeam",
    layout: AdminPrivateLayout,
    component: MyTeam,
    layoutOption: "adminPrivate",
  },
  {
    id: 28,
    path: "/profit",
    name: "profitMyTeam",
    layout: PrivateLayout,
    component: Profit,
    layoutOption: "private",
  },
  {
    id: 29,
    path: "/turntable-of-luck",
    name: "turntableOfluck",
    layout: PrivateLayout,
    component: TurntableOfLuck,
    layoutOption: "private",
  },
  {
    id: 30,
    path: "/vip",
    name: "vip",
    layout: PrivateLayout,
    component: VIP,
    layoutOption: "private",
  },
  {
    id: 31,
    path: "/admin/video-tutorial",
    name: "adminVideoTutorial",
    layout: AdminPrivateLayout,
    component: VideoTutorial,
    layoutOption: "adminPrivate",
  },
  {
    id: 32,
    path: "/video-tutorial",
    name: "videoTutorial",
    layout: PrivateLayout,
    component: VideoTutorial,
    layoutOption: "private",
  },
  {
    id: 33,
    path: "/admin/profile/accounting-records/:userId",
    name: "adminProfileAccountingRecords",
    layout: AdminPrivateLayout,
    component: AccountingRecords,
    layoutOption: "adminPrivate",
  },
  {
    id: 34,
    path: "/profile/accounting-records/:userId",
    name: "profileAccountingRecords",
    layout: PrivateLayout,
    component: AccountingRecords,
    layoutOption: "private",
  },
  {
    id: 35,
    path: "/admin/profile/personal-information/:userId",
    name: "adminProfilePersonalInformation",
    layout: AdminPrivateLayout,
    component: PersonalInformation,
    layoutOption: "adminPrivate",
  },
  {
    id: 36,
    path: "/profile/personal-information/:userId",
    name: "profilePersonalInformation",
    layout: PrivateLayout,
    component: PersonalInformation,
    layoutOption: "private",
  },
  {
    id: 37,
    path: "/profile/personal-information/detailed-information/:userId",
    name: "profileDetailedInformation",
    layout: PrivateLayout,
    component: DetailedInfo,
    layoutOption: "private",
  },
  {
    id: 38,
    path: "/admin/profile/personal-information/detailed-information/:userId",
    name: "adminProfileDetailedInformation",
    layout: AdminPrivateLayout,
    component: DetailedInfo,
    layoutOption: "adminPrivate",
  },
  {
    id: 39,
    path: "/profile/personal-information/login-password/:userId",
    name: "profileLoginPassword",
    layout: PrivateLayout,
    component: LoginPassword,
    layoutOption: "private",
  },
  {
    id: 40,
    path: "/profile/personal-information/transaction-password/:userId",
    name: "profileTransactionPassword",
    layout: PrivateLayout,
    component: TransactionPassword,
    layoutOption: "private",
  },
  {
    id: 41,
    path: "/profile/invite-friends/:userId",
    name: "profileInviteFriends",
    layout: PrivateLayout,
    component: Rewards,
    layoutOption: "private",
  },
  {
    id: 42,
    path: "/vip/display",
    name: "vipDisplay",
    layout: PrivateLayout,
    component: VipDisplay,
    layoutOption: "private",
  },
  {
    id: 43,
    path: "/vip/activate/successfully",
    name: "vipSuccess",
    layout: PrivateLayout,
    component: VipSuccessful,
    layoutOption: "private",
  },
  {
    id: 44,
    path: "/admin/holidays",
    name: "adminHolidays",
    layout: AdminPrivateLayout,
    component: Holidays,
    layoutOption: "adminPrivate",
  },
  {
    id: 45,
    path: "/admin/turntable-of-luck",
    name: "adminTurntableOfLuck",
    layout: AdminPrivateLayout,
    component: AdminTurntables,
    layoutOption: "adminPrivate",
  },
  {
    id: 46,
    path: "/admin/bumper-rewards",
    name: "adminBumperRewards",
    layout: AdminPrivateLayout,
    component: BumperRewardAdmin,
    layoutOption: "adminPrivate",
  },
  {
    id: 47,
    path: "/profile/bumperRewards/:userId",
    name: "profileBumperRewards",
    layout: PrivateLayout,
    component: BumperRewards,
    layoutOption: "private",
  },
  {
    id: 48,
    path: "/profile/turntable/:userId",
    name: "profileTurntableRewards",
    layout: PrivateLayout,
    component: TurntableOfLuck,
    layoutOption: "private",
  },
  {
    id: 49,
    path: "/admin/task-videos",
    name: "adminTaskVideos",
    layout: AdminPrivateLayout,
    component: AdminTaskVideos,
    layoutOption: "adminPrivate",
  },
  {
    id: 50,
    path: "/profile/daily-statement/:userId",
    name: "profileDailyStatement",
    layout: PrivateLayout,
    component: DailyStatement,
    layoutOption: "private",
  },
  {
    id: 51,
    path: "/admin/profile/daily-statement/:userId",
    name: "adminProfileDailyStatement",
    layout: AdminPrivateLayout,
    component: DailyStatement,
    layoutOption: "adminPrivate",
  },
];
