import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { CommonContext } from "../../App";

const PersonalInformation = () => {
  const { userId } = useParams();
  const { setActionDetails } = useContext(CommonContext);
  const checkAdminRegex = /\badmin\b/g;
  const isAdmin = checkAdminRegex.test(window.location.pathname);
  const notForAdmin = ["Login Password", "Transaction Password", "Logout"];

  const handleLogoutBtn = () => {
    setActionDetails({
      isOpen: true,
      screenName: "logout",
    });
  };

  return (
    <div className="userProfileMain">
      {/* <p className="contentTitle">Personal Information</p> */}
      <div style={{ margin: 0 }} className="userProfileOptionTabs">
        {profilePersonalInfoOptions.map((option, i) => {
          return option.path === "logout" && !isAdmin ? (
            <button
              key={`profile-tab-button-${i + 1}`}
              onClick={handleLogoutBtn}
            >
              Logout
            </button>
          ) : isAdmin && notForAdmin.includes(option.label) ? null : (
            <Link
              to={
                isAdmin
                  ? `/admin${option.path}/${userId}`
                  : `${option.path}/${userId}`
              }
              key={`profile-tab-option-${i + 1}`}
            >
              <div>
                {/* <i
                  style={{ color: option.iconColor }}
                  className={`fa-solid ${option.icon}`}
                ></i> */}
                <p> {option.label}</p>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default PersonalInformation;

const profilePersonalInfoOptions = [
  {
    label: "Detailed Information",
    path: "/profile/personal-information/detailed-information",
  },
  {
    label: "Bank Information",
    path: "/profile/personal-information/bank-details",
  },
  {
    label: "Login Password",
    path: "/profile/personal-information/login-password",
  },
  {
    label: "Transaction Password",
    path: "/profile/personal-information/transaction-password",
  },
  {
    label: "Logout",
    path: "logout",
  },
];
