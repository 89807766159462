import React, { useContext, useEffect, useState } from "react";
import { CommonContext } from "../../App";
import { getCall } from "../../api/fetchConfig";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import "./style.css";

const Rewards = () => {
  const { userDetails, setIsLoading } = useContext(CommonContext);
  const [rewardScanner, setRewardScanner] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  const rewardUrl = userDetails?.uid
    ? `${window.location.origin}/register/sponsor/${userDetails.uid}`
    : "";

  useEffect(() => {
    if (userDetails?.user_id) {
      fetchRewards();
    }
  }, [userDetails]);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 500);
    }
  }, [isCopied]);
  const fetchRewards = async () => {
    try {
      setIsLoading(true);
      const response = await getCall(`/api/${userDetails.user_id}/reward`);
      if (response && response.status === 200) {
        const res = await response.blob();
        const imageUrl = URL.createObjectURL(res);
        setIsLoading(false);
        setRewardScanner(imageUrl);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Contact your administrator");
      console.error(error);
    }
  };

  const handleWhatsappNavigate = () => {
    const message = encodeURIComponent(rewardUrl);
    window.open(`https://api.whatsapp.com/send?text=${message}`, "_blank");
  };

  const handleCopy = () => {
    window.navigator.clipboard.writeText(rewardUrl);
    setIsCopied(true);
  };

  return (
    <div className="rewardsMain">
      <p>Invite Friends</p>
      <div className="rewardsMainImgWrapper">
        {rewardScanner ? (
          <img src={rewardScanner} alt="Reward QR Code" />
        ) : (
          <div>QR Loading...</div>
        )}
      </div>
      {rewardUrl ? (
        <>
          <Link to={rewardUrl} target="_blank">
            {rewardUrl}
          </Link>
          <div>
            <button onClick={handleWhatsappNavigate}>
              <i className="fa-brands fa-whatsapp"></i>
            </button>
            <button
              onClick={handleCopy}
              className={`${isCopied ? "rewardsMainBeforeBtn" : ""}`}
              disabled={isCopied}
            >
              <i className="fa-solid fa-copy"></i>
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Rewards;
