import moment from "moment";

export const homeScreenStartingOptions = [
  {
    label: "Withdraw",
    path: "/withdraw",
    icon: "home-withdraw2",
  },
  {
    label: "Recharge",
    path: "/recharges",
    icon: "home-recharge",
  },
  {
    label: "Invite",
    path: "/rewards",
    icon: "home-invitation",
  },
  {
    label: "VIP",
    path: "/vip",
    icon: "home-vip",
  },
  {
    label: "My Team",
    path: "/my-team",
    icon: "home-team",
  },
  {
    label: "Turntable of Luck",
    path: "/turntable-of-luck",
    icon: "home-spinner",
  },
  {
    label: "Company Profile",
    path: "/company-profile",
    icon: "home-company",
  },
  {
    label: "Video Tutorial",
    path: "/video-tutorial",
    icon: "home-video",
  },
  {
    label: "Bumper Reward",
    path: "/bumper-rewards",
    icon: "home-bumper-reward-2",
  },
];

export const adminHomeScreenStartingOptions = [
  {
    label: "Users",
    path: "/admin/users",
    icon: "home-team",
  },
  {
    label: "Plans",
    path: "/admin/plans",
    icon: "home-plan",
  },
  {
    label: "Receivers List",
    path: "/admin/receivers",
    icon: "home-reciever-list",
  },
  {
    label: "Withdraws",
    path: "/admin/withdraw",
    icon: "home-withdraw2",
  },
  {
    label: "Recharges",
    path: "/admin/recharges",
    icon: "home-recharge",
  },
  {
    label: "Video Tutorial",
    path: "/admin/video-tutorial",
    icon: "home-video",
  },
  {
    label: "Holidays",
    path: "/admin/holidays",
    icon: "home-holidays",
  },
  {
    label: "Task Videos",
    path: "/admin/task-videos",
    icon: "home-task-upload-2",
  },
  {
    label: "Turntable of Luck",
    path: "/admin/turntable-of-luck",
    icon: "home-spinner",
  },
  {
    label: "Bumper Reward",
    path: "/admin/bumper-rewards",
    icon: "home-bumper-reward-2",
  },

  // {
  //   label: "Masters",
  //   path: "",
  //   icon: "home-master",
  // },
];

export const clearLocalStorage = () => {
  const localStorageKeys = ["sponsor_id", "mobile_no"];
  const checkAdminRegex = /\badmin\b/g;
  const isAdmin = checkAdminRegex.test(window.location.pathname);

  if (isAdmin) {
    localStorageKeys.push("AdminAccessToken", "AdminRefreshToken");
  } else {
    localStorageKeys.push("AccessToken", "RefreshToken");
  }

  for (const key of localStorageKeys) {
    localStorage.removeItem(key);
  }
};

export const checkNumberValue = (values, key) => {
  return values?.[key] ? parseInt(values[key]) : 0;
};

export const planFormPayload = {
  id: 0,
  plan_level: "",
  task_per_day: "",
  deposit: "",
  amount_per_task: "",
  validity: "",
  is_active: false,
};

export const adminTaskVideoPayload = {
  file: "",
};

export const luckFormPayload = {
  id: 0,
  amount: "",
  user_mobile: "",
};

export const videoTutorialFormPayload = {
  id: 0,
  is_active: false,
};

export const statusOptions = [
  { name: "Pending", value: "PENDING" },
  { name: "Success", value: "SUCCESS" },
  { name: "Failed", value: "FAILED" },
];

export const turntableStatusOptions = [
  { name: "Pending", value: "PENDING" },
  { name: "Claimed", value: "CLAIMED" },
];

export const levelOptions = [
  { name: "Level 1", value: "1" },
  { name: "Level 2", value: "2" },
  { name: "Level 3", value: "3" },
];

export const addAdminReceiverPayload = {
  file: "",
  id: 0,
  name: "",
  description: "",
  receiver_upi_id: "",
  qr_change: true,
  qr_path: "",
};
export const addAdminBumperFormPayload = {
  file: "",
  id: 0,
  product_name: "",
  user_mobile: "",
  product_change: true,
  product_path: "",
};

export const amountOptions = [
  { label: "100", value: 100 },
  { label: "150", value: 150 },
  { label: "200", value: 200 },
  { label: "250", value: 250 },
  { label: "300", value: 300 },
  { label: "350", value: 350 },
  { label: "400", value: 400 },
  { label: "450", value: 450 },
  { label: "500", value: 500 },
  { label: "550", value: 550 },
  { label: "600", value: 600 },
];

export const isBusinessDay = (holidays) => {
  const now = new Date();
  const day = now.getDay();

  const isTodayInArray = holidays.includes(moment(now).format("YYYY-MM-DD"));

  const isWeekday = day >= 1 && day <= 5;

  return !isTodayInArray && isWeekday;
};

export const isBusinessHours = () => {
  const now = new Date();
  // const day = now.getDay();
  const hour = now.getHours(); // Current hour (0-23)

  // const isWeekday = day >= 1 && day <= 5;
  const isBusinessHours = hour >= 9 && hour < 17; // 9 AM to 5 PM

  return isBusinessHours;
};
