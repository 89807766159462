import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CommonContext } from "../../App";
import { getCall } from "../../api/fetchConfig";
import { URL } from "../../api/urls";
import { toast } from "react-toastify";
import NoDataFound from "../../components/errorPage/NoDataFound";
import RechargeForm from "../../container/recharges/RechargeForm";

const RechargeUpload = () => {
  const { setIsLoading } = useContext(CommonContext);
  const { recharge_id } = useParams();
  const [rechargeDetail, setRechargeDetail] = useState({});

  useEffect(() => {
    fetchRecharge();
  }, [recharge_id]);

  const fetchRecharge = async () => {
    try {
      setIsLoading(true);
      const response = await getCall(`${URL.GET_RECEIVER_LIST}/${recharge_id}`);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setRechargeDetail(data);
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Contact your administrator");
      console.error(error);
    }
  };

  if (!Object.keys(rechargeDetail).length)
    return <NoDataFound name="Recharge Detail" />;

  return (
    <div className="mainRecharges">
      <p className="contentTitle">Recharge</p>
      <div className="mainRechargesLinks">
        <RechargeForm rechargeDetail={rechargeDetail} />
      </div>
    </div>
  );
};

export default RechargeUpload;
