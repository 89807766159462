import React, { useContext, useEffect, useState } from "react";
import {
  adminHomeScreenStartingOptions,
  homeScreenStartingOptions,
  isBusinessDay,
  isBusinessHours,
} from "../../helper";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { CommonContext } from "../../App";
import Modal from "../../components/ui/modal";
import { getCall } from "../../api/fetchConfig";
import { URL } from "../../api/urls";
import { toast } from "react-toastify";

const Options = () => {
  const [holidaysData, setHolidaysData] = useState([]);
  const { actionDetails, setActionDetails, userDetails, setUserDetails } =
    useContext(CommonContext);
  const navigate = useNavigate();
  const checkAdminRegex = /\badmin\b/g;
  const isAdmin = checkAdminRegex.test(window.location.pathname);
  const options = isAdmin
    ? adminHomeScreenStartingOptions
    : homeScreenStartingOptions;
  const isWithDrawAlertScreenEnable =
    actionDetails?.screenName === "withdrawAlert" && actionDetails?.isOpen;

  useEffect(() => {
    if (!isAdmin) {
      fetchHolidays();
    }
  }, [isAdmin]);

  const fetchHolidays = async () => {
    try {
      const response = await getCall(URL.GET_HOLIDAYS);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setHolidaysData(data?.holidays);
          setUserDetails((prev) => ({
            ...prev,
            holidays: data?.holidays,
          }));
        } else {
          toast.error(error);
        }
      }
    } catch (error) {
      toast.error("Something went wrong. Contact your administrator");
      console.error(error);
    }
  };

  const getWithdrawAlertMode = () => {
    if (!isBusinessDay(holidaysData)) return "day";
    if (!isBusinessHours()) return "time";
    return null;
  };

  const showWithdrawAlert = (mode) => {
    setActionDetails({
      screenName: "withdrawAlert",
      isOpen: true,
      mode,
    });
  };

  const navigateButton = (path) => {
    if (path === "/withdraw") {
      const alertMode = getWithdrawAlertMode();
      if (alertMode) {
        showWithdrawAlert(alertMode);
        return false;
      }
    }
    navigate(path);
  };

  return (
    <>
      <div className={`home-screen-starting-option ${isAdmin ? "admin" : ""}`}>
        {options.map((item, index) => {
          const pathName =
            item.path === "/my-team"
              ? `${item.path}/${userDetails?.user_id}`
              : item.path;
          const imgSrc = `${process.env.PUBLIC_URL}/assets/${item.icon}.png`;

          return (
            <button
              onClick={() => navigateButton(pathName)}
              key={`home-screen-starting-options-${index + 1}`}
            >
              <img src={imgSrc} alt={item.icon} />
              {item.label}
            </button>
          );
        })}
      </div>

      <Modal isOpen={isWithDrawAlertScreenEnable}>
        <div className="WithdrawAlertModalContent">
          <p>
            {actionDetails?.mode === "time"
              ? "Withdrawals are allowed only between 9 a.m. and 5 p.m."
              : actionDetails?.mode === "day"
              ? "Withdrawals are not allowed today as it is a holiday."
              : ""}
          </p>
        </div>
      </Modal>
    </>
  );
};

export default Options;
