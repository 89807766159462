import React, { useContext, useEffect, useState } from "react";
import { CommonContext } from "../../App";
import { putCall } from "../../api/fetchConfig";
import { toast } from "react-toastify";
import { statusOptions } from "../../helper";

const TableCellStatusSelection = ({
  detail,
  value,
  isStatusEdit,
  setIsStatusEdit,
  fetchList,
  urlForUpdateStatus,
}) => {
  const [inputStatusValue, setInputStatusValue] = useState(value || "");
  const { setIsLoading } = useContext(CommonContext);

  useEffect(() => {
    setInputStatusValue(value || "");
  }, [isStatusEdit]);

  const handleEditClick = () => {
    // Toggle the open state for the specific item
    if (isStatusEdit.id === detail.id && isStatusEdit.isOpen) {
      setIsStatusEdit({ id: null, isOpen: false });
    } else {
      setIsStatusEdit({ id: detail.id, isOpen: true });
    }
  };

  const handleChange = async (e) => {
    const { value } = e.target;
    setInputStatusValue(value);

    const body = {
      id: detail.id,
      status: value,
    };

    try {
      setIsLoading(true);
      const response = await putCall(`${urlForUpdateStatus}/${body.id}`, body);
      if (response && response.status === 200) {
        const res = await response.json();

        if (!res || Object.keys(res).length === 0) {
          throw new Error("Empty response from server");
        }

        const { status_code, detail, error } = res;
        if (status_code === 200) {
          toast.success(detail);
          fetchList();
          setIsStatusEdit({ id: null, isOpen: false });
        } else {
          toast.error(error);
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Contact your administrator");
      console.error(error);
    }
  };

  if (isStatusEdit.id === detail.id && isStatusEdit.isOpen) {
    return (
      <>
        <select
          onChange={handleChange}
          value={inputStatusValue || ""}
          name="table_status"
        >
          {/* <option value="">None</option> */}
          {statusOptions.map((item) => (
            <option
              key={`status-table-option-${item.value}`}
              value={item.value}
            >
              {item.name}
            </option>
          ))}
        </select>
        <button onClick={handleEditClick}>
          <i className="fa-solid fa-xmark"></i>
        </button>
      </>
    );
  }

  return (
    <>
      {value}
      <button onClick={handleEditClick}>
        <i className="fa-solid fa-pen"></i>
      </button>
    </>
  );
};

export default TableCellStatusSelection;
