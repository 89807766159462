import React, { useContext } from "react";
import "./style.css";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import { CommonContext } from "../../../App";
import NoDataFound from "../../errorPage/NoDataFound";

const TableContainer = ({
  data = [],
  columns = [],
  keys = [],
  tableName = "",
  children = null,
  paginationName = null,
  total_count = 0,
}) => {
  const { isLoading, paginationData, setPaginationData } =
    useContext(CommonContext);
  const paginationDetails = paginationName
    ? paginationData[paginationName]
    : null;

  const handleRowsPerPage = (e) => {
    const { value } = e.target;
    setPaginationData((prev) => ({
      ...prev,
      [paginationName]: {
        ...prev[paginationName],
        rows_per_page: value,
      },
    }));
  };

  const handleGoNext = () => {
    setPaginationData((prev) => ({
      ...prev,
      [paginationName]: {
        ...prev[paginationName],
        page_no: prev[paginationName].page_no + 1,
      },
    }));
  };

  const handleGoBack = () => {
    setPaginationData((prev) => ({
      ...prev,
      [paginationName]: {
        ...prev[paginationName],
        page_no: prev[paginationName].page_no - 1,
      },
    }));
  };

  if (isLoading)
    return (
      <div className="tableNoDataContainer">
        <p> Fetching {tableName} ...</p>
      </div>
    );

  if (!data.length) return <NoDataFound name={tableName} />;

  return (
    <div className="tableParent">
      <div className={`mainTableWrapper ${paginationName ? "pagination" : ""}`}>
        <table className="mainTable">
          <TableHead columns={columns} tableName={tableName} />
          {children || (
            <TableBody keys={keys} data={data} tableName={tableName} />
          )}
        </table>
      </div>

      {paginationName ? (
        <div className="tablePagination">
          <div>
            <p>Rows Per Page</p>
            <select
              onChange={handleRowsPerPage}
              value={paginationDetails.rows_per_page}
              name="rows_per_page"
              id="rows_per_page"
            >
              {paginationRowsOptions.map((item) => (
                <option key={`rows-per-page-options-${item}`} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>

          <div>
            <span>
              {Math.min(
                paginationDetails.page_no * paginationDetails.rows_per_page + 1,
                paginationDetails.total_count || total_count
              )}{" "}
              -{" "}
              {Math.min(
                (paginationDetails.page_no + 1) *
                  paginationDetails.rows_per_page,
                paginationDetails.total_count || total_count
              )}{" "}
              of
            </span>
            <strong>{paginationDetails.total_count || total_count}</strong>
            <button
              disabled={paginationDetails.page_no === 0}
              onClick={handleGoBack}
            >
              <i className="fa-solid fa-chevron-left"></i>
            </button>
            <button
              disabled={
                (paginationDetails.total_count || total_count) <=
                  paginationDetails.rows_per_page ||
                Math.min(
                  (paginationDetails.page_no + 1) *
                    paginationDetails.rows_per_page,
                  paginationDetails.total_count || total_count
                ) === (paginationDetails.total_count || total_count)
              }
              onClick={handleGoNext}
            >
              <i className="fa-solid fa-chevron-right"></i>
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TableContainer;

const paginationRowsOptions = [10, 30, 50];
