import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { routes } from "./router";
import ErrorPage from "../components/errorPage/ErrorPage";
import Modal from "../components/ui/modal";
import SessionExpired from "../components/SessionExpired";

const PublicRoute = (props) => {
  const token = localStorage.getItem("AccessToken");
  const { path, Component, Layout } = props;

  return !token ? (
    <Layout path={path} Component={Component} />
  ) : (
    <Navigate to="/" />
  );
};

const PrivateRoute = (props) => {
  const token = localStorage.getItem("AccessToken");
  const { path, Component, Layout } = props;

  return token ? (
    <Layout path={path} Component={Component} />
  ) : (
    <Navigate to="/login" />
  );
};

const AdminPublicRoute = (props) => {
  const token = localStorage.getItem("AdminAccessToken");
  const { path, Component, Layout } = props;

  return !token ? (
    <Layout path={path} Component={Component} />
  ) : (
    <Navigate to="/admin" />
  );
};

const AdminPrivateRoute = (props) => {
  const token = localStorage.getItem("AdminAccessToken");
  const { path, Component, Layout } = props;

  return token ? (
    <Layout path={path} Component={Component} />
  ) : (
    <Navigate to="/admin/login" />
  );
};

const layoutComponent = {
  public: PublicRoute,
  private: PrivateRoute,
  adminPublic: AdminPublicRoute,
  adminPrivate: AdminPrivateRoute,
};
const RouterApp = () => {
  const isSessionExpired = sessionStorage.getItem("SessionExpired") || false;

  return (
    <>
      <BrowserRouter future={{ v7_relativeSplatPath: true }}>
        <Suspense>
          <Routes>
            {routes.map((route) => {
              const {
                path,
                component: Component,
                layout: Layout,
                name,
                id,
                layoutOption,
              } = route;

              const LayoutRoute = layoutComponent[layoutOption];

              const GetLayoutComponent = layoutOption ? (
                <LayoutRoute Layout={Layout} Component={Component} />
              ) : null;

              return GetLayoutComponent ? (
                <React.Fragment key={`${name}-${id}`}>
                  <Route path={path} element={GetLayoutComponent} />
                  <Route
                    path="*"
                    element={<ErrorPage errorMsg="Page not found" />}
                  />
                </React.Fragment>
              ) : null;
            })}
          </Routes>
        </Suspense>
      </BrowserRouter>

      <Modal removeHeader isOpen={isSessionExpired}>
        {isSessionExpired ? <SessionExpired /> : null}
      </Modal>
    </>
  );
};

export default RouterApp;
