import React from "react";
import TableContainer from "../../ui/table";
import moment from "moment";
import TableCellStatusSelection from "../TableCellStatusSelection";
import { Link } from "react-router-dom";
import NoDataFound from "../../errorPage/NoDataFound";

const TableCommonToShowStatusRelated = ({
  data,
  columns,
  tableName,
  keys,
  isStatusEdit,
  setIsStatusEdit,
  fetchList,
  urlForUpdateStatus,
  wontCheckProfile,
  paginationName,
  total_count,
}) => {
  const checkProfileRegex = /\bprofile\b/g;
  const isProfile =
    checkProfileRegex.test(window.location.pathname) && !wontCheckProfile;
  const domainUrl = "https://api.r999.in";

  return data.length ? (
    <TableContainer
      tableName={tableName}
      columns={columns}
      data={data}
      total_count={total_count}
      paginationName={paginationName}
    >
      <tbody>
        {data.map((item, itemInd) => {
          return (
            <tr
              key={`${tableName.replace(/\s/g, "-")}-data-row-${itemInd + 1}`}
            >
              {keys.map((key, keyInd) => {
                let value = item?.[key] || "-";

                if (
                  key === "wallet_amount" ||
                  key === "balance_amount" ||
                  key === "amount" ||
                  key === "withdrawal_amount"
                ) {
                  value = item?.[key]?.toString();
                }

                if (
                  key === "withdrawal_time" ||
                  key === "updated_time" ||
                  key === "date" ||
                  key === "claimed_date" ||
                  key === "created_date"
                ) {
                  value = item?.[key]
                    ? moment(item?.[key]).format("DD-MM-YYYY")
                    : "-";
                }

                return (
                  <td
                    style={{
                      ...(!wontCheckProfile && !isProfile && key === "status"
                        ? { paddingTop: 0, paddingBottom: 0 }
                        : {}),
                    }}
                    key={`${tableName.replace(/\s/g, "-")}-data-key-${
                      keyInd + 1
                    }`}
                  >
                    {!wontCheckProfile && !isProfile && key === "status" ? (
                      <TableCellStatusSelection
                        detail={item}
                        value={value}
                        isStatusEdit={isStatusEdit}
                        setIsStatusEdit={setIsStatusEdit}
                        fetchList={fetchList}
                        urlForUpdateStatus={urlForUpdateStatus}
                      />
                    ) : key === "transaction_ss" ? (
                      <Link to={`${domainUrl}/${value}`} target="_blank">
                        View
                      </Link>
                    ) : (
                      value
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </TableContainer>
  ) : (
    <NoDataFound name={tableName} />
  );
};

export default TableCommonToShowStatusRelated;
