import React, { useContext, useEffect, useState } from "react";
import { CommonContext } from "../../App";
import { getCall } from "../../api/fetchConfig";
import { URL } from "../../api/urls";
import { toast } from "react-toastify";
import NoDataFound from "../../components/errorPage/NoDataFound";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/ui/modal";

const Plans = () => {
  const { setIsLoading, actionDetails } = useContext(CommonContext);
  const [planList, setPlanList] = useState([]);
  const deniedTasks =
    actionDetails?.screenName === "deniedTasks" && actionDetails?.isOpen;

  useEffect(() => {
    fetchPlanList();
  }, []);

  const fetchPlanList = async () => {
    try {
      setIsLoading(true);
      const response = await getCall(URL.GET_PLANS);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setPlanList(data);
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Contact your administrator");
      console.error(error);
    }
  };

  return (
    <>
      <div className="homePlan">
        {planList.length ? (
          <>
            <p className="contentTitle">Plans</p>
            <PlanChips plans={planList} />
          </>
        ) : (
          <NoDataFound name="Plans" />
        )}
      </div>

      <Modal isOpen={deniedTasks}>
        <div className="WithdrawAlertModalContent">
          <p>You don't have this Plan. Kindly Recharge</p>
        </div>
      </Modal>
    </>
  );
};

const PlanChips = ({ plans }) => {
  const imgSrc = `${process.env.PUBLIC_URL}/assets/star-blinking.gif`;
  const { userDetails, setActionDetails } = useContext(CommonContext);
  const plan_id = userDetails.plan_id;
  const navigate = useNavigate();

  const navigateButton = (path) => {
    // if (!isBusinessDay(userDetails?.holidays)) {
    // setActionDetails({
    //   screenName: "planNotToSee",
    //   isOpen: true,
    // });
    //   return null;
    // }
    navigate(path);
  };

  return (
    <div className="homePlanChips">
      {plans.map((plan) => (
        <span
          role="button"
          tabIndex="0"
          onClick={() =>
            plan_id === plan.id
              ? navigateButton("/tasks")
              : setActionDetails({
                  screenName: "deniedTasks",
                  isOpen: true,
                })
          }
          className={`homePlanChip ${
            plan_id === plan.id ? "homeActivePlanChip" : ""
          }`}
          key={`plan-${plan.id}`}
        >
          <img src={imgSrc} alt="star blinking gif" /> {plan.plan_level}
        </span>
      ))}
    </div>
  );
};

export default Plans;
