// USER AUTH
const USER_AUTH = {
  REGISTER_USER: "/api/register",
  LOGIN_USER: "/api/login",
  REFRESH_USER: "/api/refresh",
  CURRENT_USER: "/me",
  GET_USER_RECHARGE: "/api/recharge",
  GET_TASKS: "/api/tasks",
  GET_WITHDRAWAL: "/api/withdrawal",
  GET_DAILY_STATEMENT: "/api/daily-statement",
  GET_MY_TEAM: "/api/team/level",
  GET_HOME_BASE: "/api/home/base",
  PLAN_SUBSCRIPTION: "/api/plan-subscription",
  GET_TURNTABLES: "/api/spin-reward",
  GET_BUMPER_REWARD: "/api/bumper-reward",
};

// ADMIN
const ADMIN = {
  GET_COMPANY_PROFILE: "/api/admin/masters/company-profile",
  GET_USERS: "/api/admin/user/user_list",
  GET_PLANS: "/api/admin/masters/plans",
  GET_RECEIVER_LIST: "/api/admin/receiver-list",
  GET_ADMIN_RECHARGE: "/api/admin/recharges",
  GET_TASK_LIST: "/api/admin/masters/upload-video",
  GET_ADMIN_WITHDRAWAL: "/api/admin/withdrawal",
  GET_VIDEO_TUTORIAL: "/api/admin/masters/video-tutorial",
  GET_HOLIDAYS: "/api/admin/masters/holidays",
  GET_ADMIN_TURNTABLES: "/api/admin/spin-reward",
  GET_ADMIN_TURNTABLES_USERS: "/api/users-list",
  GET_ADMIN_BUMPER_REWARD: "/api/admin/bumper-reward",
};

// ADMIN AUTH
const ADMIN_AUTH = {
  ADMIN_LOGIN_USER: "/api/admin/login",
};

export const URL = {
  ...USER_AUTH,
  ...ADMIN,
  ...ADMIN_AUTH,
};
