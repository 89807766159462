import React from "react";
import { profitDetails, profitDetailTable } from "../../constant/profitDetails";
import "./style.css";
import TableContainer from "../../components/ui/table";

const Profit = () => {
  // const imgSrc = `${process.env.PUBLIC_URL}/assets/plan-details-table.png`;

  return (
    <div className="profitDetailMain">
      <p className="contentTitle center">Profit</p>

      {profitDetails.map((detail, index) => {
        const { name, imgName, tableDetails, contents } = detail;
        const { name: tableName, columns, keys } = tableDetails;
        const imgSrc = `${process.env.PUBLIC_URL}/assets/${imgName}.png`;

        return (
          <div key={`profit-detail-${index}`}>
            <span className="contentBoxTitle">{name}</span>
            <img src={imgSrc} width="100%" alt={`${imgName} png`} />
            <TableContainer
              tableName={tableName}
              columns={columns}
              keys={keys}
              data={profitDetailTable[tableName]}
            />
            {contents?.length ? <ContentDisplay data={contents} /> : null}
          </div>
        );
      })}
    </div>
  );
};

const ContentDisplay = ({ data }) => {
  return data.map((item, index) => {
    const { label, value } = item;
    return (
      <div className="contentBoxBelowTable" key={`profit-content-${index + 1}`}>
        {label ? <span className="contentBoxTitle">{label}</span> : null}
        <span>{value}</span>
      </div>
    );
  });
};

export default Profit;
