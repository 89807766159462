import React, { useContext, useEffect, useState } from "react";
import { CommonContext } from "../../../App";
import { getCall } from "../../../api/fetchConfig";
import { URL } from "../../../api/urls";
import { toast } from "react-toastify";
import NoDataFound from "../../../components/errorPage/NoDataFound";
import { adminTaskVideoPayload } from "../../../helper";
import Modal from "../../../components/ui/modal";
import TaskVideoDelete from "./TaskVideoDelete";
import TaskForm from "./TaskForm";

const AdminTaskVideos = () => {
  const { setIsLoading, actionDetails, setActionDetails } =
    useContext(CommonContext);
  const [taskVideos, setTaskVideos] = useState({
    total_count: 0,
    data: [],
  });
  const isTaskVideoModalEnable =
    actionDetails?.screenName === "addAdminTaskVideo" && actionDetails?.isOpen;

  const isTaskVideoDelModalEnable =
    actionDetails?.screenName === "deleteAdminTaskVideo" &&
    actionDetails?.isOpen;

  useEffect(() => {
    fetchTaskVideoList();
  }, []);

  const fetchTaskVideoList = async () => {
    try {
      setIsLoading(true);
      const response = await getCall(URL.GET_TASK_LIST);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setTaskVideos(data);
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Contact your administrator");
      console.error(error);
    }
  };

  const triggerPlan = (screenName, data, label) => {
    setActionDetails({
      screenName,
      data,
      isOpen: true,
      label,
    });
  };

  return (
    <>
      <div className="adminPlanMain">
        <div className="adminPlanTitleWrapper">
          <p className="contentTitle">Task Videos</p>
          <button
            onClick={() =>
              triggerPlan(
                "addAdminTaskVideo",
                adminTaskVideoPayload,
                "Add Video"
              )
            }
            type="button"
          >
            Add Task Video +
          </button>
        </div>

        <div className="adminPlanMainTableWrapper">
          {taskVideos.data.length ? (
            <div className="taskVideoWrapper">
              {taskVideos.data.map((item) => (
                <TaskVideoMain
                  key={`task-video-${item.id}`}
                  video={item}
                  triggerPlan={triggerPlan}
                />
              ))}
            </div>
          ) : (
            <NoDataFound name="Task Videos" />
          )}
        </div>
      </div>

      <Modal
        label={isTaskVideoModalEnable ? actionDetails?.label : ""}
        isOpen={isTaskVideoModalEnable || isTaskVideoDelModalEnable}
      >
        {isTaskVideoModalEnable ? (
          <TaskForm fetchList={fetchTaskVideoList} />
        ) : (
          <TaskVideoDelete fetchList={fetchTaskVideoList} />
        )}
      </Modal>
    </>
  );
};

const TaskVideoMain = ({ video, triggerPlan }) => {
  const domainUrl = "https://api.r999.in";
  const { thumbnail_path, file_path } = video;
  const constructVideoUrl = `${domainUrl}/${file_path}`;
  const constructThumbnailUrl = `${domainUrl}/${thumbnail_path}`;

  return (
    <div>
      <button
        onClick={() =>
          triggerPlan(
            "deleteAdminTaskVideo",
            video,
            `Are you sure to delete this Video?`
          )
        }
      >
        <i className="fa-solid fa-trash"></i>
      </button>
      <video controls poster={constructThumbnailUrl}>
        <source src={constructVideoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default AdminTaskVideos;
