import React, { useContext } from "react";
import { CommonContext } from "../../../App";
import { useFormik } from "formik";
import { putCall } from "../../../api/fetchConfig";
import { URL as APIURL } from "../../../api/urls";
import { toast } from "react-toastify";
import "./style.css";
import { addAdminReceiverPayload } from "../../../helper";

const validate = (values) => {
  const err = {};

  if (!values.name) {
    err.name = "Name is required";
  }
  if (!values.receiver_upi_id) {
    err.receiver_upi_id = "Receiver UPI ID is required";
  }

  if (values.file) {
    const validTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (
      typeof values.file === "object" &&
      !validTypes.includes(values.file.type)
    ) {
      err.file = "Only JPG, JPEG, and PNG file are allowed.";
    }
  } else {
    err.file = "File is required.";
  }

  return err;
};

const ReceiverForm = ({ fetchList }) => {
  const { setIsLoading, actionDetails, setActionDetails } =
    useContext(CommonContext);
  const domainUrl = "https://api.r999.in";

  const handleModalClose = () => {
    setActionDetails({});
  };

  const receiverFormik = useFormik({
    enableReinitialize: true,
    initialValues: actionDetails?.data || { ...addAdminReceiverPayload },
    onSubmit: (values) => handleSubmit(values),
    validate,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "file") {
      receiverFormik.setFieldValue(name, e.target.files[0]);
    } else {
      receiverFormik.setFieldValue(name, value);
    }
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();

    `${domainUrl}/${values.qr_path}` !== values.file &&
      formData.append("file", values.file);

    const jsonData = {
      id: values.id,
      name: values.name,
      description: values.description,
      receiver_upi_id: values.receiver_upi_id,
      qr_change: `${domainUrl}/${values.qr_path}` !== values.file,
      qr_path: values.qr_path,
    };
    formData.append("json_data", JSON.stringify(jsonData));

    try {
      setIsLoading(true);
      const response = await putCall(APIURL.GET_RECEIVER_LIST, formData, true);
      if (response && response.status === 200) {
        const res = await response.json();
        if (!res || Object.keys(res).length === 0) {
          throw new Error("Empty response from server");
        }
        const { status_code, detail, error } = res;
        if (status_code === 200) {
          toast.success(detail);
          handleModalClose();
          fetchList();
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Contact your administrator");
      console.error(error);
    }
  };

  const displayFileName =
    receiverFormik.values?.file?.name || actionDetails?.data?.fileName || "";

  return (
    <form className="adminReceiverForm" onSubmit={receiverFormik.handleSubmit}>
      <div className="adminReceiverFormSubtitle">
        <p>QR Code</p>
        <div className="adminReceiverFormScanner">
          {receiverFormik.values?.file ? (
            <img
              width="100%"
              height="100%"
              src={
                typeof receiverFormik.values?.file === "object"
                  ? URL.createObjectURL(receiverFormik.values?.file)
                  : receiverFormik.values?.file
              }
              alt="QR Code"
            />
          ) : (
            <div>QR Not Found !!!</div>
          )}
        </div>
      </div>

      <span className="errorText"></span>

      {formFields.map((field) => {
        const { name, label, type } = field;

        return (
          <React.Fragment key={name}>
            <label className="adminReceiverFormLabel">
              <p>{label}</p>
              {type === "textArea" ? (
                <textarea
                  onChange={handleChange}
                  value={receiverFormik.values?.[name]}
                  name={name}
                  rows="4"
                />
              ) : (
                <input
                  onChange={handleChange}
                  {...(type === "file"
                    ? {}
                    : { value: receiverFormik.values?.[name] })}
                  type={type}
                  name={name}
                />
              )}
            </label>
            {type === "file" && displayFileName && (
              <span className="adminReceiverFormFileLabel">
                {displayFileName}
              </span>
            )}
            <span className="errorText">{receiverFormik.errors[name]}</span>
          </React.Fragment>
        );
      })}

      <div className="adminReceiverFormBtnGroup">
        <button onClick={handleModalClose} type="button">
          Close
        </button>
        <button type="submit">Update</button>
      </div>
    </form>
  );
};

export default ReceiverForm;

const formFields = [
  {
    name: "file",
    label: "Upload QR Code",
    type: "file",
  },
  {
    name: "name",
    label: "Name",
    type: "text",
  },
  {
    name: "receiver_upi_id",
    label: "Receiver UPI ID",
    type: "text",
  },
  {
    name: "description",
    label: "Description",
    type: "textArea",
  },
];
