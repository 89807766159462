import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { CommonContext } from "../../../App";
import { getCall } from "../../../api/fetchConfig";
import { URL } from "../../../api/urls";
import {
  addAdminBumperFormPayload,
  turntableStatusOptions,
} from "../../../helper";
import TableContainer from "../../../components/ui/table";
import Modal from "../../../components/ui/modal";
import { Link } from "react-router-dom";
import AdminBumperForm from "./BumperAddForm";
import BumperDel from "./BumperDel";

const paginationName = "adminBumperTable";

const BumperRewardAdmin = () => {
  const { setIsLoading, actionDetails, setActionDetails, paginationData } =
    useContext(CommonContext);
  const [adminBumperList, setAdminBumperList] = useState({
    total_count: 0,
    data: [],
  });
  const [statusValue, setStatusValue] = useState("PENDING");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [debouncedPhoneNumberValue, setDebouncedPhoneNumberValue] =
    useState("");
  const isBumperRewardFormModal =
    actionDetails?.screenName === "addBumperReward" && actionDetails?.isOpen;
  const isBumperRewardDeleteModal =
    actionDetails?.screenName === "deleteBumperReward" && actionDetails?.isOpen;
  const domainUrl = "https://api.r999.in";

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedPhoneNumberValue(phoneNumber);
    }, 500);

    return () => clearTimeout(timer);
  }, [phoneNumber]);

  useEffect(() => {
    fetchAdminBumperRewardList();
  }, [statusValue, debouncedPhoneNumberValue, paginationData[paginationName]]);

  const fetchAdminBumperRewardList = async () => {
    try {
      const { page_no, rows_per_page } = paginationData[paginationName];
      const offset = page_no * rows_per_page;
      const apiUrl = `${URL.GET_ADMIN_BUMPER_REWARD}?st=${statusValue}&mn=${debouncedPhoneNumberValue}&offset=${offset}&limit=${rows_per_page}`;
      setIsLoading(true);
      const response = await getCall(apiUrl);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setAdminBumperList(data);
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Contact your administrator");
      console.error(error);
    }
  };

  const handlePhoneNumber = (e) => {
    const input = e.target.value;

    if (input.length <= 10) {
      setPhoneNumber(input);
    }
  };

  const handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const triggerLuck = (screenName, data, label) => {
    setActionDetails({
      screenName,
      data,
      isOpen: true,
      label,
    });
  };

  return (
    <>
      <div className="adminRecharges">
        <div className="adminRechargesHeader">
          <div className="adminRechargesHeaderLabelAndSearch">
            <p className="contentTitle">Bumper</p>
            <input
              onKeyPress={handleKeyPress}
              maxLength="10"
              value={phoneNumber}
              onChange={handlePhoneNumber}
              type="text"
              placeholder="Search With Mobile No"
            />
          </div>
          <div className="adminRechargesHeaderStatus">
            {turntableStatusOptions.map((status) => (
              <label key={`bumper-status-option-${status.value}`}>
                <input
                  onChange={(e) => setStatusValue(e.target.value)}
                  value={status.value}
                  name="status"
                  type="radio"
                  checked={statusValue === status.value}
                />
                {status.name}
              </label>
            ))}
            <button
              style={{ alignSelf: "center" }}
              className="addButton"
              onClick={() =>
                triggerLuck(
                  "addBumperReward",
                  { ...addAdminBumperFormPayload },
                  "Add Reward"
                )
              }
              type="button"
            >
              Add Reward +
            </button>
          </div>
        </div>

        <div className="adminRechargesTable">
          <TableContainer
            tableName={bumperRewardTableDetails.tableName}
            columns={bumperRewardTableDetails.columns.filter((fil) =>
              statusValue === "PENDING"
                ? fil !== "Claimed Date"
                : statusValue === "CLAIMED"
                ? fil !== "Action"
                : fil
            )}
            data={adminBumperList.data}
            paginationName={paginationName}
            total_count={adminBumperList.total_count}
          >
            <tbody>
              {adminBumperList.data.map((item, itemInd) => {
                return (
                  <tr
                    key={`${bumperRewardTableDetails.tableName.replace(
                      /\s/g,
                      "-"
                    )}-data-row-${itemInd + 1}`}
                  >
                    {bumperRewardTableDetails.keys
                      .filter((fil) =>
                        statusValue === "PENDING" ? fil !== "claimed_date" : fil
                      )
                      .map((key, keyInd) => {
                        let value = item?.[key] || "-";

                        if (key === "claimed_date" || key === "created_date") {
                          value = item?.[key]
                            ? moment(item?.[key]).format("DD-MM-YYYY")
                            : "-";
                        }
                        if (key === "amount") {
                          value = item?.[key].toString();
                        }

                        return (
                          <td
                            key={`${bumperRewardTableDetails.tableName.replace(
                              /\s/g,
                              "-"
                            )}-data-key-${keyInd + 1}`}
                          >
                            {key === "product_image" && value ? (
                              <Link
                                to={`${domainUrl}/${value}`}
                                target="_blank"
                              >
                                View
                              </Link>
                            ) : (
                              value
                            )}
                          </td>
                        );
                      })}
                    {statusValue === "PENDING" ? (
                      <td>
                        <div className="adminPlanTableButtonGroup">
                          <button
                            className="turntableEditButton"
                            onClick={() => {
                              const qrScanner = item?.product_image
                                ? `${domainUrl}/${item.product_image}`
                                : "";
                              triggerLuck(
                                "addBumperReward",
                                {
                                  fileName: qrScanner.includes("/")
                                    ? qrScanner.split("/").pop()
                                    : "",
                                  file: qrScanner,
                                  id: item.id || 0,
                                  product_name: item.product_name || "",
                                  user_mobile: item.mobile_no || "",
                                  product_change: false,
                                  product_path: item.product_image || "",
                                },
                                `Edit Reward "${item.product_name}"`
                              );
                            }}
                          >
                            <i className="fa-solid fa-pen-to-square"></i>
                          </button>
                          <button
                            onClick={() =>
                              triggerLuck(
                                "deleteBumperReward",
                                item,
                                `Are you sure to delete "${item.product_name}" Reward for this "${item.mobile_no}"?`
                              )
                            }
                          >
                            <i className="fa-solid fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    ) : null}
                  </tr>
                );
              })}
            </tbody>
          </TableContainer>
        </div>
      </div>

      <Modal
        label={isBumperRewardFormModal ? actionDetails?.label : ""}
        isOpen={isBumperRewardFormModal || isBumperRewardDeleteModal}
      >
        {isBumperRewardFormModal && (
          <AdminBumperForm fetchList={fetchAdminBumperRewardList} />
        )}
        {isBumperRewardDeleteModal && (
          <BumperDel fetchList={fetchAdminBumperRewardList} />
        )}
      </Modal>
    </>
  );
};

export default BumperRewardAdmin;

const bumperRewardTableDetails = {
  columns: [
    "Mobile Number",
    "Product Name",
    "Status",
    "Product Image",
    "Claimed Date",
    "Created Date",
    "Action",
  ],
  keys: [
    "mobile_no",
    "product_name",
    "status",
    "product_image",
    "claimed_date",
    "created_date",
  ],
  tableName: "Bumper Reward",
};
