import React from "react";
import { Link } from "react-router-dom";

const ReferralBanner = () => {
  return (
    <Link to="/rewards">
      <div className="referralMain">
        <img
          src={`${process.env.PUBLIC_URL}/assets/referralBanner.jpg`}
          alt="referral-img"
        />
      </div>
    </Link>
  );
};

export default ReferralBanner;
