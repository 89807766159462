import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { CommonContext } from "../../App";
import { getCall } from "../../api/fetchConfig";
import { URL } from "../../api/urls";
import { toast } from "react-toastify";
import NoDataFound from "../../components/errorPage/NoDataFound";
import { Link } from "react-router-dom";

const Recharges = () => {
  const { setIsLoading } = useContext(CommonContext);
  const [rechargeList, setRechargeList] = useState({
    total_count: 0,
    data: [],
  });

  useEffect(() => {
    fetchRechargeList();
  }, []);

  const fetchRechargeList = async () => {
    try {
      setIsLoading(true);
      const response = await getCall(URL.GET_RECEIVER_LIST);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setRechargeList(data);
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Contact your administrator");
      console.error(error);
    }
  };

  if (!rechargeList.data.length) return <NoDataFound name="Recharges" />;

  return (
    <div className="mainRecharges">
      <p className="contentTitle">Recharges</p>
      <div className="mainRechargesLinks">
        {rechargeList.data.map((item) => {
          return (
            <Link to={`/recharges/${item.id}`} key={`recharge-link-${item.id}`}>
              <div className="mainRechargesLinksCard">
                <span>{item.name}</span>
                <span>{item.description || ""}</span>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Recharges;
