import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { URL } from "../../api/urls";
import { toast } from "react-toastify";
import { getCall, putCall } from "../../api/fetchConfig";
import { CommonContext } from "../../App";
import GiftPopup from "../../components/ui/modal/GiftPopup";
import BumperCongrats from "./BumperCongrats";

const BumperRewards = () => {
  const [bumperDetails, setBumperDetails] = useState({
    total_count: 0,
    data: [],
  });
  const { setIsLoading, actionDetails, setActionDetails } =
    useContext(CommonContext);
  const createArrForLoopImg = Array.from(
    { length: 20 },
    (_, index) => index + 1
  );
  const isBumperSuccessAlert =
    actionDetails?.screenName === "bumperSuccess" && actionDetails?.isOpen;

  useEffect(() => {
    fetchBumperRewardList();
  }, []);

  const fetchBumperRewardList = async () => {
    try {
      const response = await getCall(URL.GET_BUMPER_REWARD);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setBumperDetails(data);
        } else {
          toast.error(error);
        }
      }
    } catch (error) {
      toast.error("Something went wrong. Contact your administrator");
      console.error(error);
    }
  };

  const getReward = () => {
    setActionDetails({
      screenName: "bumperSuccess",
      isOpen: true,
      label: "Rewards",
      data: { ...bumperDetails.data[0] },
    });
  };

  return (
    <>
      <div className="bumperRewardMain">
        {/* <p className="contentTitle">Bumper Rewards</p> */}
        <div className="totalSpin">
          <p className="contentTitle">Bumper Rewards</p>

          <div>
            <div className="totalSpinGold">
              <img
                src={`${process.env.PUBLIC_URL}/assets/gold-ticket.png`}
                alt="gold-ticket.png"
              />
              <span>{bumperDetails.total_count || 0}</span>
            </div>
            <button
              onClick={getReward}
              disabled={!bumperDetails.total_count}
              className="spinButton"
              style={{
                cursor: !bumperDetails.total_count ? "not-allowed" : "pointer",
              }}
            >
              Get Reward
            </button>
          </div>
        </div>

        <div className="rewardImages">
          {createArrForLoopImg.map((no) => {
            const imgSrc = `${process.env.PUBLIC_URL}/assets/bumper-reward-${no}.jpeg`;

            return (
              <img key={imgSrc} src={imgSrc} alt={`bumper-reward-${no}`} />
            );
          })}
        </div>
      </div>

      <GiftPopup isOpen={isBumperSuccessAlert}>
        {isBumperSuccessAlert && (
          <BumperCongrats
            fetchList={fetchBumperRewardList}
            bumperDetails={bumperDetails}
          />
        )}
      </GiftPopup>
    </>
  );
};

export default BumperRewards;
