/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import TableContainer from "../../../components/ui/table";
import { CommonContext } from "../../../App";
import { getCall } from "../../../api/fetchConfig";
import { URL } from "../../../api/urls";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from "../../../components/ui/modal";
import EditUserForm from "./EditUserForm";
import DeleteUserForm from "./DeleteUserForm";

const paginationName = "adminUserTable";
const AdminUsers = () => {
  const { setIsLoading, actionDetails, paginationData, setActionDetails } =
    useContext(CommonContext);
  const [userList, setUserList] = useState({
    total_count: 0,
    data: [],
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [debouncedPhoneNumberValue, setDebouncedPhoneNumberValue] =
    useState("");
  const navigate = useNavigate();
  const isAdminUserModalEnable =
    actionDetails?.screenName === "editAdminUser" && actionDetails?.isOpen;
  const isAdminUserDeleteEnable =
    actionDetails?.screenName === "deleteAdminUser" && actionDetails?.isOpen;

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedPhoneNumberValue(phoneNumber);
    }, 500);

    return () => clearTimeout(timer);
  }, [phoneNumber]);

  useEffect(() => {
    fetchUserList();
  }, [debouncedPhoneNumberValue, paginationData[paginationName]]);

  const fetchUserList = async () => {
    try {
      const { page_no, rows_per_page } = paginationData[paginationName];
      const offset = page_no * rows_per_page;
      setIsLoading(true);
      const response = await getCall(
        `${URL.GET_USERS}?m_no=${debouncedPhoneNumberValue}&offset=${offset}&limit=${rows_per_page}`
      );
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setUserList(data);
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Contact your administrator");
      console.error(error);
    }
  };

  // const handleSponsorEdit = (e, item) => {
  //   const { checked } = e.target;
  //   const newList = { ...userList };

  //   if (item?.id) {
  //     newList["data"] = newList["data"].map((val) => {
  //       const obj = { ...val };
  //       if (val.id === item.id) {
  //         obj["is_sponsor"] = checked;
  //       }
  //       return obj;
  //     });
  //   }

  //   setUserList(newList);
  // };

  const handlePhoneNumber = (e) => {
    const input = e.target.value;

    if (input.length <= 10) {
      setPhoneNumber(input);
    }
  };

  const handleKeyPress = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const triggerAction = (screenName, data, label) => {
    setActionDetails({
      screenName,
      data,
      isOpen: true,
      label,
    });
  };

  return (
    <>
      <div className="adminUserMain">
        <div className="adminUserMainHeader">
          <p className="contentTitle">Users</p>
          <input
            onKeyPress={handleKeyPress}
            maxLength="10"
            value={phoneNumber}
            onChange={handlePhoneNumber}
            type="text"
            placeholder="Search With Mobile No"
          />
        </div>
        <div className="adminUserMainTableWrapper">
          <TableContainer
            tableName={tableDetails.tableName}
            columns={tableDetails.columns}
            data={userList.data}
            paginationName={paginationName}
            total_count={userList.total_count}
          >
            <tbody>
              {userList.data.map((item, itemInd) => {
                return (
                  <tr
                    key={`${tableDetails.tableName.replace(
                      /\s/g,
                      "-"
                    )}-data-row-${itemInd + 1}`}
                  >
                    {tableDetails.keys.map((key, keyInd) => {
                      let value = item?.[key] || "-";

                      if (key === "is_admin" || key === "is_sponsor") {
                        value = item?.[key] ? "Yes" : "No";
                      }
                      if (key === "wallet_balance") {
                        value = item?.[key].toString();
                      }

                      return (
                        <td
                          key={`${tableDetails.tableName.replace(
                            /\s/g,
                            "-"
                          )}-data-key-${keyInd + 1}`}
                        >
                          {value}
                          {/* {key === "is_sponsor" ? (
                          <SponsorEditBox
                            item={item}
                            handleSponsorEdit={handleSponsorEdit}
                          />
                        ) : (
                          value
                        )} */}
                        </td>
                      );
                    })}

                    <td
                      style={{ padding: 0 }}
                      className="adminUserMainTableViewButton"
                    >
                      <button
                        onClick={() =>
                          triggerAction(
                            "deleteAdminUser",
                            item,
                            `Delete "${item.mobile_no}"`
                          )
                        }
                      >
                        <i className="fa-solid fa-trash"></i>
                      </button>

                      <button
                        onClick={() =>
                          triggerAction(
                            "editAdminUser",
                            item,
                            `Edit "${item.mobile_no}"`
                          )
                        }
                      >
                        <i className="fa-solid fa-pen-to-square"></i>
                      </button>
                      <button
                        onClick={() => navigate(`/admin/profile/${item.id}`)}
                        type="button"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </TableContainer>
        </div>
      </div>

      <Modal
        label={isAdminUserDeleteEnable ? "" : actionDetails?.label}
        isOpen={isAdminUserDeleteEnable || isAdminUserModalEnable}
      >
        {isAdminUserModalEnable ? (
          <EditUserForm fetchList={fetchUserList} />
        ) : (
          <DeleteUserForm fetchList={fetchUserList} />
        )}
      </Modal>
    </>
  );
};

// const SponsorEditBox = ({ item, handleSponsorEdit }) => {
//   const value = item?.is_sponsor || false;

//   return (
// <input
//   type="checkbox"
//   checked={value}
//   onChange={(e) => handleSponsorEdit(e, item)}
// />
//   );
// };

export default AdminUsers;

const tableDetails = {
  columns: [
    // "Name",
    // "Email",
    "Mobile Number",
    // "Is Admin",
    "Is Sponsor",
    "Sponsor ID",
    "Wallet Balance",
    // "Address",
    "Action",
  ],
  keys: [
    // "username",
    // "email",
    "mobile_no",
    // "is_admin",
    "is_sponsor",
    "uid",
    "wallet_balance",
    // "Address",
  ],
  tableName: "Users",
};
