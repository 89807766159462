import React from "react";
import "./style.css";
import LogoText from "../ui/logoText";
import { Link } from "react-router-dom";

const ErrorPage = ({ errorMsg }) => {
  return (
    <div className="errorPage">
      <LogoText />
      <p className="errorPage-para-1">Oops!</p>
      <p className="errorPage-para-2">
        Sorry, an unexpected error has occurred.
      </p>
      <p className="errorPage-para-3">{errorMsg}</p>
      <Link to={-1}>Go Back</Link>
    </div>
  );
};

export default ErrorPage;
