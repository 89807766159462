import React from "react";

const TableHead = ({ columns, tableName }) => {
  return (
    <thead>
      <tr>
        {columns.map((col, i) => (
          <th key={`${tableName.replace(/\s/g, "-")}-columns-${i + 1}`}>
            {col}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
