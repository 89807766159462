import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NoDataFound from "../../components/errorPage/NoDataFound";
import "./style.css";
import { CommonContext } from "../../App";
import { postCall } from "../../api/fetchConfig";
import { URL } from "../../api/urls";
import moment from "moment";
import { toast } from "react-toastify";

const TaskDisplay = () => {
  const { setIsLoading, userDetails } = useContext(CommonContext);
  const { videoUrl } = useParams();
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const domainUrl = "https://api.r999.in";
  const decodeVideoUrl = decodeURIComponent(videoUrl);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.addEventListener("timeupdate", handleTimeUpdate);
      videoElement.addEventListener("ended", handleVideoEnd);

      return () => {
        videoElement.removeEventListener("timeupdate", handleTimeUpdate);
        videoElement.removeEventListener("ended", handleVideoEnd);
      };
    }
  }, []);

  const handleTimeUpdate = () => {
    const videoElement = videoRef.current;

    if (videoElement && videoElement.currentTime >= videoElement.duration) {
      setIsSubmitEnabled(true);
    }
  };

  const handleVideoEnd = () => {
    setIsSubmitEnabled(true);
  };

  if (
    !decodeVideoUrl ||
    decodeVideoUrl === "null" ||
    decodeVideoUrl === "undefined"
  ) {
    return <NoDataFound name="Video is not supported" isFullName={true} />;
  }

  const handleVideoSubmit = async () => {
    const body = {
      id: 0,
      video_id: decodeVideoUrl,
      date: moment().format("YYYY-MM-DD"),
      user_id: userDetails.user_id,
      plan_id: userDetails.plan_id || 0,
    };

    try {
      setIsLoading(true);
      const response = await postCall(URL.GET_TASKS, body);
      if (response && response.status === 200) {
        const res = await response.json();

        if (!res || Object.keys(res).length === 0) {
          throw new Error("Empty response from server");
        }

        const { status_code, detail, error } = res;
        if (status_code === 200) {
          toast.success(detail);
          navigate("/tasks");
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Contact your administrator");
      console.error(error);
    }
  };

  const constructVideoUrl = `${domainUrl}/${decodeVideoUrl}`;

  return (
    <div className="taskDisplayMain">
      <div className="taskDisplayMainVideo">
        <video
          ref={videoRef}
          width="100%"
          height="100%"
          controls={!isSubmitEnabled}
          autoPlay
        >
          <source src={constructVideoUrl} type="video/mp4" />
        </video>
      </div>

      <button
        onClick={handleVideoSubmit}
        disabled={!isSubmitEnabled}
        type="button"
      >
        Submit
      </button>
    </div>
  );
};

export default TaskDisplay;
