import React from "react";

const NoDataFound = ({ name, isFullName = false }) => {
  return (
    <div className="noDataContainer">
      <i className="fa-solid fa-ban"></i>
      <p>
        {name} {isFullName ? "" : "are not available"}
      </p>
    </div>
  );
};

export default NoDataFound;
