import React, { useContext } from "react";
import { CommonContext } from "../../App";
import { putCall } from "../../api/fetchConfig";
import { toast } from "react-toastify";
import { URL } from "../../api/urls";
import ConfettiExplosion from "react-confetti-explosion";

const CongratsWheel = ({ fetchList }) => {
  const { actionDetails, setActionDetails, setIsLoading } =
    useContext(CommonContext);
  const partyImgSrc = `${process.env.PUBLIC_URL}/assets/party-popper.png`;

  const handleClaim = async () => {
    try {
      setIsLoading(true);
      const response = await putCall(
        URL.GET_TURNTABLES + "/" + actionDetails?.data?.id
      );
      if (response && response.status === 200) {
        const res = await response.json();
        if (!res || Object.keys(res).length === 0) {
          throw new Error("Empty response from server");
        }
        const { status_code, detail, error } = res;
        if (status_code === 200) {
          toast.success(detail);
          fetchList();
          setActionDetails({});
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Contact your administrator");
      console.error(error);
    }
  };

  return (
    <>
      <ConfettiExplosion zIndex={9999} />
      <div style={{ paddingTop: "16px" }} className="congratsMain">
        <img src={partyImgSrc} alt="party pop" />
        <span>
          You are so lucky! Congratulations on winning{" "}
          <strong style={{ display: "block", fontSize: "32px" }}>
            ₹{actionDetails.label}
          </strong>
        </span>
        <button
          style={{ marginBottom: "16px" }}
          onClick={handleClaim}
          className="spinButton"
        >
          Claim
        </button>
      </div>
    </>
  );
};

export default CongratsWheel;
