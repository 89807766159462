/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import TableContainer from "../../../components/ui/table";
import { CommonContext } from "../../../App";
import { getCall } from "../../../api/fetchConfig";
import { URL } from "../../../api/urls";
import { toast } from "react-toastify";
import Modal from "../../../components/ui/modal";
import { planFormPayload } from "../../../helper";
import PlanForm from "../../../container/admin/plans/PlanForm";
import PlanDelete from "../../../container/admin/plans/PlanDelete";

const paginationName = "adminPlanTable";

const AdminPlans = () => {
  const { setIsLoading, actionDetails, setActionDetails, paginationData } =
    useContext(CommonContext);
  const [planList, setPlanList] = useState({
    total_count: 0,
    data: [],
  });
  const isPlanFormModalEnable =
    actionDetails?.screenName === "addPlan" && actionDetails?.isOpen;

  const isPlanDelModalEnable =
    actionDetails?.screenName === "deletePlan" && actionDetails?.isOpen;

  useEffect(() => {
    fetchPlanList();
  }, [paginationData[paginationName]]);

  const fetchPlanList = async () => {
    try {
      const { page_no, rows_per_page } = paginationData[paginationName];
      const offset = page_no * rows_per_page;
      setIsLoading(true);
      const response = await getCall(
        `${URL.GET_PLANS}?offset=${offset}&limit=${rows_per_page}`
      );
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setPlanList((prev) => ({ ...prev, data }));
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Contact your administrator");
      console.error(error);
    }
  };

  const triggerPlan = (screenName, data, label) => {
    setActionDetails({
      screenName,
      data,
      isOpen: true,
      label,
    });
  };

  return (
    <>
      <div className="adminPlanMain">
        <div className="adminPlanTitleWrapper">
          <p className="contentTitle">Plans</p>
          <button
            onClick={() => triggerPlan("addPlan", planFormPayload, "Add Plan")}
            type="button"
          >
            Add Plan +
          </button>
        </div>
        <div className="adminPlanMainTableWrapper">
          <TableContainer
            tableName={tableDetails.tableName}
            columns={tableDetails.columns}
            data={planList.data}
          >
            <tbody>
              {planList.data.map((item, itemInd) => {
                return (
                  <tr
                    key={`${tableDetails.tableName.replace(
                      /\s/g,
                      "-"
                    )}-data-row-${itemInd + 1}`}
                  >
                    {tableDetails.keys.map((key, keyInd) => {
                      let value = item?.[key] || "-";

                      if (key === "is_active" || key === "is_delete") {
                        value = item?.[key] ? "Yes" : "No";
                      }
                      if (
                        key === "amount_per_task" ||
                        key === "deposit" ||
                        key === "task_per_day" ||
                        key === "validity"
                      ) {
                        value = item?.[key].toString();
                      }

                      return (
                        <td
                          key={`${tableDetails.tableName.replace(
                            /\s/g,
                            "-"
                          )}-data-key-${keyInd + 1}`}
                        >
                          {value}
                        </td>
                      );
                    })}
                    <td>
                      <div className="adminPlanTableButtonGroup">
                        <button
                          onClick={() =>
                            triggerPlan(
                              "addPlan",
                              item,
                              `Edit "${item.plan_level}" Plan`
                            )
                          }
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                        </button>
                        <button
                          onClick={() =>
                            triggerPlan(
                              "deletePlan",
                              item,
                              `Are you sure to delete "${item.plan_level}" Plan?`
                            )
                          }
                        >
                          <i className="fa-solid fa-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </TableContainer>
        </div>
      </div>

      <Modal
        label={isPlanFormModalEnable ? actionDetails?.label : ""}
        isOpen={isPlanFormModalEnable || isPlanDelModalEnable}
      >
        {isPlanFormModalEnable ? (
          <PlanForm fetchPlanList={fetchPlanList} />
        ) : (
          <PlanDelete fetchPlanList={fetchPlanList} />
        )}
      </Modal>
    </>
  );
};

export default AdminPlans;

const tableDetails = {
  columns: [
    "Plan Level",
    "Deposit",
    "Task Per Day",
    "Amount Per Task",
    "Validity",
    "Is Active",
    "Actions",
    // "Is Delete",
  ],
  keys: [
    "plan_level",
    "deposit",
    "task_per_day",
    "amount_per_task",
    "validity",
    "is_active",
    // "is_delete",
  ],
  tableName: "Plans",
};
