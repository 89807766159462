import React, { useContext } from "react";
import "./style.css";
import { CommonContext } from "../../../../App";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LogoText from "../../../ui/logoText";
import CircleLoading from "../../../ui/circleLoading";
import Modal from "../../../ui/modal";
import { clearLocalStorage } from "../../../../helper";

const AdminPrivateLayout = (props) => {
  const { Component } = props;
  const { isLoading, setIsLoading, actionDetails, setActionDetails } =
    useContext(CommonContext);
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const isLogoutScreenEnable =
    actionDetails?.screenName === "logout" && actionDetails?.isOpen;

  const handleBackButton = () => {
    navigate(-1);
  };

  const handleLogoutBtn = () => {
    setActionDetails({
      isOpen: true,
      screenName: "logout",
    });
  };

  const handleLogoutModalClose = () => setActionDetails({});

  const handleLogoutYes = () => {
    setIsLoading(true);
    setTimeout(() => {
      clearLocalStorage();
      setIsLoading(false);
      handleLogoutModalClose();
      navigate("/admin/login");
    }, 500);
  };

  return (
    <>
      <div className="privateLayout adminPrivateLayout">
        <div className="privateLayoutHeader">
          <div className="privateLayoutHeaderStart">
            {pathname !== "/admin" ? (
              <button type="button" onClick={handleBackButton}>
                <i className="fa-solid fa-arrow-left"></i>
              </button>
            ) : null}
            <Link to="/admin">
              <LogoText />
            </Link>
          </div>
          <button onClick={handleLogoutBtn} type="button">
            <i className="fa-solid fa-right-from-bracket"></i>
          </button>
        </div>
        <div className="privateLayoutComponent">
          <Component {...props} />
        </div>

        {!Object.keys(actionDetails).length && isLoading ? (
          <div className="privateLayoutLoading">
            <CircleLoading /> <span>Loading...</span>
          </div>
        ) : null}
      </div>
      <Modal isOpen={isLogoutScreenEnable}>
        <div className="logoutModalContent">
          <p>Are you sure want to logout?</p>
        </div>
        <div className="logoutModalGroupBtn">
          <button onClick={handleLogoutModalClose} type="button">
            No
          </button>
          <button onClick={handleLogoutYes} type="button">
            Yes
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AdminPrivateLayout;
