import React, { useContext, useEffect, useState } from "react";
import { CommonContext } from "../../../App";
import { getCall } from "../../../api/fetchConfig";
import { URL } from "../../../api/urls";
import { toast } from "react-toastify";
import "./style.css";
import NoDataFound from "../../../components/errorPage/NoDataFound";
import Modal from "../../../components/ui/modal";
import ReceiverForm from "../../../container/admin/receivers/ReceiverForm";
import ReceiverDelete from "../../../container/admin/receivers/ReceiverDelete";
import { addAdminReceiverPayload } from "../../../helper";

const AdminReceivers = () => {
  const { setIsLoading, actionDetails, setActionDetails } =
    useContext(CommonContext);
  const [receiverList, setReceiverList] = useState({
    total_count: 0,
    data: [],
  });
  const domainUrl = "https://api.r999.in";

  const isAddReceiver =
    actionDetails?.screenName === "addReceiver" && actionDetails?.isOpen;

  const isEditReceiver =
    actionDetails?.screenName === "editReceiver" && actionDetails?.isOpen;

  const isDelReceiver =
    actionDetails?.screenName === "delReceiver" && actionDetails?.isOpen;

  useEffect(() => {
    fetchReceiverList();
  }, []);

  const handleModalTrigger = (isOpen, label, screenName, data) => {
    setActionDetails({
      isOpen,
      label,
      screenName,
      data,
    });
  };

  const fetchReceiverList = async () => {
    try {
      setIsLoading(true);
      const response = await getCall(URL.GET_RECEIVER_LIST);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setReceiverList(data);
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Contact your administrator");
      console.error(error);
    }
  };

  return (
    <>
      <div className="adminReceivers">
        <div className="adminReceiversHeader">
          <p className="contentTitle">Receivers</p>
          <button
            onClick={() =>
              handleModalTrigger(true, "Add New Receiver", "addReceiver", {
                ...addAdminReceiverPayload,
              })
            }
            type="button"
          >
            Add Receiver +
          </button>
        </div>

        {receiverList.data.length ? (
          <div className="adminReceiversLinks">
            {receiverList.data.map((item) => {
              return (
                <div
                  key={`receiver-link-${item.id}`}
                  className="adminReceiversLinksCard"
                >
                  <span className="span1">{item.name}</span>
                  <span className="span2">{item.description || ""}</span>

                  <div className="adminReceiversLinksCardButtonGroup">
                    <button
                      onClick={() => {
                        const qrScanner = item?.upi_qr
                          ? `${domainUrl}/${item.upi_qr}`
                          : "";

                        handleModalTrigger(
                          true,
                          `Edit Receiver (${item.name})`,
                          "editReceiver",
                          {
                            fileName: qrScanner.includes("/")
                              ? qrScanner.split("/").pop()
                              : "",
                            file: qrScanner,
                            id: item.id || 0,
                            name: item.name || "",
                            description: item.description || "",
                            receiver_upi_id: item.receiver_upi_id || "",
                            qr_change: false,
                            qr_path: item.upi_qr || "",
                          }
                        );
                      }}
                    >
                      <i className="fa-solid fa-pen-to-square"></i>
                    </button>
                    <button
                      onClick={() =>
                        handleModalTrigger(
                          true,
                          `Delete Receiver (${item.name})`,
                          "delReceiver",
                          { ...item }
                        )
                      }
                    >
                      <i className="fa-solid fa-trash"></i>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <NoDataFound name="Receivers" />
        )}
      </div>

      <Modal
        label={isAddReceiver || isEditReceiver ? actionDetails?.label : ""}
        isOpen={isAddReceiver || isEditReceiver || isDelReceiver}
      >
        {isAddReceiver || isEditReceiver ? (
          <ReceiverForm fetchList={fetchReceiverList} />
        ) : (
          <ReceiverDelete fetchList={fetchReceiverList} />
        )}
      </Modal>
    </>
  );
};

export default AdminReceivers;
