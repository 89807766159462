import React, { useContext, useState } from "react";
import { CommonContext } from "../../App";
import { putCall } from "../../api/fetchConfig";
import { toast } from "react-toastify";
import { URL } from "../../api/urls";
import ConfettiExplosion from "react-confetti-explosion";

const BumperCongrats = ({ fetchList }) => {
  const { actionDetails, setIsLoading, setActionDetails } =
    useContext(CommonContext);
  const sixCards = Array.from({ length: 6 }, (_, index) => index + 1);
  const partyImgSrc = `${process.env.PUBLIC_URL}/assets/party-popper.png`;
  const giftImgSrc = `${process.env.PUBLIC_URL}/assets/gift-box.png`;
  const disableGiftImgSrc = `${process.env.PUBLIC_URL}/assets/disable-gift.png`;
  const [openRewardBox, setOpenRewardBox] = useState(null);
  const [rewardIndex, setRewardIndex] = useState(null);
  const domainUrl = "https://api.r999.in";

  const handleClaim = async () => {
    try {
      setIsLoading(true);
      const response = await putCall(
        URL.GET_BUMPER_REWARD + "/" + actionDetails?.data?.id
      );
      if (response && response.status === 200) {
        const res = await response.json();
        if (!res || Object.keys(res).length === 0) {
          throw new Error("Empty response from server");
        }
        const { status_code, detail, error } = res;
        if (status_code === 200) {
          toast.success(detail);
          fetchList();
          setActionDetails({});
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Contact your administrator");
      console.error(error);
    }
  };

  return (
    <>
      {openRewardBox ? <ConfettiExplosion zIndex={9999} /> : null}
      <div className="congratsMain">
        {!openRewardBox && (
          <p style={{ marginTop: "16px" }}>{actionDetails.label}</p>
        )}
        {!openRewardBox && (
          <div style={{ marginBottom: "16px" }} className="rewardCards">
            {sixCards.map((reward) => {
              return openRewardBox === reward ? (
                <div className="rewardImg" key={`reward-${reward}`}>
                  <img
                    src={`${domainUrl}/${actionDetails.data.product_image}`}
                    alt={`gift pop ${reward}`}
                  />
                </div>
              ) : (
                <button
                  disabled={!!openRewardBox}
                  className={rewardIndex === reward ? "shake" : ""}
                  onClick={() => {
                    setRewardIndex(reward);
                    setTimeout(() => {
                      setOpenRewardBox(reward);
                    }, 1000);
                  }}
                  key={`reward-${reward}`}
                >
                  <img
                    src={openRewardBox ? disableGiftImgSrc : giftImgSrc}
                    alt={`gift pop ${reward}`}
                  />
                </button>
              );
            })}
          </div>
        )}

        {openRewardBox ? (
          <>
            {/* <img
            style={{ width: "50px", height: "50px" }}
            src={partyImgSrc}
            alt="party pop"
          /> */}
            <p style={{ marginTop: "16px" }}>Congratulations</p>
            <span>
              You are so lucky! Congratulations on winning{" "}
              <strong>{actionDetails?.data?.product_name}</strong>
            </span>
            <img
              style={{ width: "80px", height: "80px" }}
              src={`${domainUrl}/${actionDetails.data.product_image}`}
              alt={`gift pop ${actionDetails?.data?.product_name}`}
            />
          </>
        ) : null}

        {openRewardBox && (
          <button
            style={{ marginBottom: "16px" }}
            onClick={handleClaim}
            className="spinButton"
          >
            Claim
          </button>
        )}
      </div>
    </>
  );
};

export default BumperCongrats;
