const hindiFirstText =
  "आरआरआर की स्थापना 2024 में हुई थी। हम न्यूयॉर्क में विज्ञापनों को बढ़ावा देने वाली सबसे प्रमुख कंपनियों में से एक हैं। आर. आर. आर. के संस्थापक जॉन विल्सन हैं और रचनात्मक निर्देशक ब्राउन डेविस हैं।";
const hindiSecText =
  "विज्ञापन निर्माताओं ने एक रणनीतिक साझेदारी स्थापित की है। हम नई लॉन्च करने वाली कंपनियों को लघु विज्ञापन प्रचार सेवाएं प्रदान करेंगे। वर्तमान में प्रमुख उत्पाद कंपनियों ने दुनिया भर में हजारों उत्पादों का उत्पादन किया है और यह संख्या दिन-प्रतिदिन बढ़ रही है। इसलिए कंपनी इस उत्पाद को पूरी दुनिया में बढ़ावा देना चाहती है।";
const hindiThirdText =
  "विज्ञापन निर्माताओं ने एक रणनीतिक साझेदारी स्थापित की है। हम नई लॉन्च करने वाली कंपनियों को लघु विज्ञापन प्रचार सेवाएं प्रदान करेंगे। वर्तमान में प्रमुख उत्पाद कंपनियों ने दुनिया भर में हजारों उत्पादों का उत्पादन किया है और यह संख्या दिन-प्रतिदिन बढ़ रही है। इसलिए कंपनी इस उत्पाद को पूरी दुनिया में बढ़ावा देना चाहती है। ";
const hindiFourthText =
  "आर. आर. आर. हमेशा से ही लोगों को आकर्षित करने वाला रहा है। हमने हमेशा देने और मदद करने पर जोर दिया है। आरआरआर में शामिल होने के लिए नौकरी की जरूरत वाले किसी भी व्यक्ति का हम स्वागत करते हैं। हम सभी सदस्यों के लिए रोजगार खोजने और आय बढ़ाने के लिए प्रतिबद्ध हैं। आरआरआर में शामिल होने वाला कोई भी सामान्य कर्मचारी प्लेटफॉर्म का आनंद ले सकता है और अधिक पैसा कमा सकता है। आर. आर. आर. में, हम आपको अपने जीवन को आगे बढ़ाने और बेहतर बनाने में मदद करने के लिए चुनौतियां और अवसर प्रदान करेंगे। यदि आपके पास क्षमता, जुनून है और आप अच्छा करना चाहते हैं और तेजी से स्वतंत्रता चाहते हैं तो हमारे साथ जुड़ें।";
const englishFirstText =
  "RRR is founded in 2024. We are one of the most leading ads promoting company in New York. The founder of RRR is John Wilson and the creative director is Brown Davis.";
const englishSecText =
  "Ads producers have established a strategic partnership. We will provide short ads promotion services to the new launching companies. At present major product companies have produced thousands of products in worldwide and this count is increase day by day. So the company want to promote the product to all over the world.";
const englishThirdText =
  "In order to complete the promotion of product faster the company recruites employees extensively in major talent markets around the world to meet the needs of major partners for product promotion. In order to achieve better personal management, we will invest more funds and budget and carry out of our work. So that every employee can participate in recruitment and management work, and at the same time up time correspondingly more income. This is a rare development of opportunity.";
const englishFourthText =
  "RRR has always been people oriented. We have always insisted on giving and helping. We welcome anyone who needs a job to join RRR. We are committed to finding employment and increasing income for all members. Any general employee who joins RRR can enjoy the platforms and make more money. At RRR, we will provide you with challenges and opportunities to help you continue to grow an improve your life. If you have the ability, passion and want to gainwell and freedom faster come and join us.";

export const hindiCompanyProfiles = [
  hindiFirstText,
  hindiSecText,
  hindiThirdText,
  hindiFourthText,
];

export const englishCompanyProfiles = [
  englishFirstText,
  englishSecText,
  englishThirdText,
  englishFourthText,
];
