import { useFormik } from "formik";
import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CommonContext } from "../../App";
import { putCall } from "../../api/fetchConfig";
import { toast } from "react-toastify";

const loginInputFields = [
  {
    name: "old_password",
    err: "Old Login Password is required",
    label: "Old Login Password",
    type: "password",
  },
  {
    name: "password",
    err: "Login Password is required",
    label: "Login Password",
    type: "text",
  },
  {
    name: "conf_pass",
    err: "Confirm Login Password is required",
    label: "Confirm Login Password",
    type: "password",
  },
];

const loginPasswordValidate = (values) => {
  const errors = {};

  loginInputFields.forEach((item) => {
    if (!values[item.name]) {
      errors[item.name] = item.err;
    }
  });

  if (
    values.password &&
    values.conf_pass &&
    values.password !== values.conf_pass
  ) {
    errors.conf_pass =
      "Confirm Password does not match the Password field. Please re-enter.";
  }

  return errors;
};

const LoginPassword = () => {
  const { userId } = useParams();
  const { setIsLoading } = useContext(CommonContext);
  const navigate = useNavigate();

  const loginPassFormik = useFormik({
    initialValues: {
      user_id: parseInt(userId || 0),
      old_password: "",
      password: "",
      conf_pass: "",
    },
    onSubmit: async (values) => {
      try {
        const dataToSend = { ...values };
        delete dataToSend.conf_pass;

        const url = `/api/${userId}/settings/profile-password-change`;
        setIsLoading(true);
        const response = await putCall(url, dataToSend);
        if (response && response.status === 200) {
          const res = await response.json();

          if (!res || Object.keys(res).length === 0) {
            throw new Error("Empty response from server");
          }

          const { status_code, detail, error } = res;
          if (status_code === 200) {
            toast.success(detail);
            navigate(`/profile/personal-information/${userId}`);
          } else {
            toast.error(error);
          }
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        toast.error("Something went wrong. Contact your administrator");
        console.error(error);
      }
    },
    validate: loginPasswordValidate,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <form onSubmit={loginPassFormik.handleSubmit} className="bankDetailsForm">
      <p className="contentTitle">Login Password</p>
      {loginInputFields.map((field) => {
        return (
          <div
            className="bankDetailsFormInputWrapper"
            key={`login-form-field-${field.name}`}
          >
            <div>
              <label htmlFor={field.name}>{field.label}</label>
              <input
                name={field.name}
                onChange={loginPassFormik.handleChange}
                value={loginPassFormik?.values?.[field.name] || ""}
                id={field.name}
                type={field.type}
              />
            </div>
            <span className="errorText">
              {loginPassFormik.errors[field.name]}
            </span>
          </div>
        );
      })}
      <button type="submit">Update</button>
    </form>
  );
};

export default LoginPassword;
