import React from "react";
import { Link } from "react-router-dom";

const RegistrationSuccess = () => {
  const sponsor_id = localStorage.getItem("sponsor_id");
  const mobile_no = localStorage.getItem("mobile_no");

  return (
    <div className="registrationSuccessMain">
      <p>REGISTRATION SUCCESSFUL</p>

      <div>
        <p>Your profile registered successfully.</p>
        <p>
          Your Referral Member ID is <span>{sponsor_id} </span>.
        </p>
        <p>
          Your Login Mobile No is <span>{mobile_no} </span>.
        </p>
      </div>
      <Link to="/login">Click here to Login </Link>
    </div>
  );
};

export default RegistrationSuccess;
