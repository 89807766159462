import React, { useEffect, useState } from "react";

let bannerImgDetails = [
  {
    src: `${process.env.PUBLIC_URL}/assets/banner1.jpg`,
    alt: "banner 1 img",
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/banner2.jpg`,
    alt: "banner 2 img",
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/banner3.jpg`,
    alt: "banner 3 img",
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/banner4.jpg`,
    alt: "banner 4 img",
  },
  {
    src: `${process.env.PUBLIC_URL}/assets/banner5.jpg`,
    alt: "banner 5 img",
  },
];

const TopBanner = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    let slider = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % bannerImgDetails.length);
    }, 3000);
    return () => clearInterval(slider);
  }, [activeIndex]);

  return (
    <div className="topBannerMain">
      {bannerImgDetails.map((banner, index) => {
        const { src, alt } = banner;
        let position = "bannerNextSlide";

        if (index === activeIndex) {
          position = "bannerActiveSlide";
        }
        if (index !== activeIndex) {
          position = "bannerLastSlide";
        }

        return (
          <img
            className={position}
            key={`home-banner-${index + 1}`}
            src={src}
            alt={alt}
          />
        );
      })}
    </div>
  );
};

export default TopBanner;
