import React from "react";
import { Link } from "react-router-dom";

const VipSuccessful = () => {
  const imgSrc = `${process.env.PUBLIC_URL}/assets/success.png`;

  return (
    <div className="rechargeSuccess">
      <p>RECHARGE SUCCESSFULLY DONE !!!</p>

      <img src={imgSrc} alt="success png" />

      <div>
        <p>Thank you for recharging!</p>
        <p>Your recharge will be reflected in your account within 24 hours.</p>
        <p>We appreciate your patience and support.</p>
      </div>

      <Link to="/">Click here to go Home</Link>
    </div>
  );
};

export default VipSuccessful;
