import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { CommonContext } from "../../App";
import { getCall } from "../../api/fetchConfig";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import moment from "moment";

const UserProfile = () => {
  const { userId } = useParams();
  const { setIsLoading } = useContext(CommonContext);
  const [personalDetails, setPersonalDetails] = useState({});
  const checkAdminRegex = /\badmin\b/g;
  const isAdmin = checkAdminRegex.test(window.location.pathname);
  const notForAdmin = [
    "Invite Friends",
    "Bumper Reward",
    "Help Book",
    "Turntable of Luck",
  ];

  useEffect(() => {
    if (userId) {
      fetchPersonalDetails();
    }
  }, [userId]);

  const fetchPersonalDetails = async () => {
    try {
      const url = `/api/${userId}/settings/base`;
      setIsLoading(true);
      const response = await getCall(url);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setPersonalDetails(data);
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Contact your administrator");
      console.error(error);
    }
  };
  const bgImgSrc = `${process.env.PUBLIC_URL}/assets/profile-bg.png`;
  const profileSrc = `${process.env.PUBLIC_URL}/assets/profile-user.png`;

  return (
    <div className="userProfileMain">
      <div
        style={{ backgroundImage: `url(${bgImgSrc})`, backgroundSize: "cover" }}
        className="userProfileView"
      >
        <div className="userProfileImg">
          <img src={profileSrc} alt="userProfile" />
        </div>
        {/* <div className="userProfileImg">
          <i className="fa-solid fa-user"></i>
        </div> */}
        <span>{personalDetails?.mobile_no || "-"}</span>
        <div className="userProfileKpiCards">
          {profileKpiCars.map((kpiCard) => {
            const value =
              kpiCard.name === "plan_level"
                ? personalDetails?.plan?.[kpiCard.name] || ""
                : personalDetails?.[kpiCard.name] ||
                  (kpiCard.name === "wallet_balance" ? "0" : "-");
            return (
              <div key={kpiCard.name}>
                <p>{kpiCard.label}</p>
                <p>{value}</p>
              </div>
            );
          })}
        </div>
        <p className="effectiveDate">
          Effective Date:{" "}
          {personalDetails?.subscribed_on && personalDetails?.expires_on
            ? `${moment(personalDetails?.subscribed_on).format(
                "YYYY-MM-DD"
              )} - ${moment(personalDetails?.expires_on).format("YYYY-MM-DD")}`
            : "-"}
        </p>
      </div>

      <div className="userProfileDetailKpiCards">
        {profileDetailKpiCars.map((detail) => {
          const { name, label, bgColorPrimaryColor, bgColorSecondaryColor } =
            detail;
          return (
            <div
              style={{
                backgroundColor: bgColorSecondaryColor,
                backgroundImage: `linear-gradient(315deg, ${bgColorSecondaryColor} 0%, ${bgColorPrimaryColor} 90%)`,
              }}
              key={name}
            >
              <span>{label}</span>
              <span>{personalDetails?.[name] || 0}</span>
            </div>
          );
        })}
      </div>

      <div className="userProfileOptionTabs">
        {profileOptions.map((option, i) => {
          return isAdmin && notForAdmin.includes(option.label) ? null : (
            <Link
              to={
                isAdmin
                  ? `/admin${option.path}/${userId}`
                  : `${option.path}/${userId}`
              }
              key={`profile-tab-option-${i + 1}`}
            >
              <div>
                <i
                  style={{ color: option.iconColor }}
                  className={
                    option.icon === "fa-files-pinwheel"
                      ? `fa-brands ${option.icon}`
                      : `fa-solid ${option.icon}`
                  }
                ></i>
                <p>{option.label}</p>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default UserProfile;

const profileOptions = [
  {
    label: "Personal Details",
    path: "/profile/personal-information",
    icon: "fa-user-pen",
    iconColor: "#74C0FC",
  },
  // {
  //   label: "Bank Details",
  //   path: "/profile/bank-details",
  // },
  {
    label: "My Team",
    path: "/profile/my-team",
    icon: "fa-people-group",
    iconColor: "#63E6BE",
  },
  {
    label: "Accounting Records",
    path: "/profile/accounting-records",
    icon: "fa-book",
    iconColor: "#B197FC",
  },
  {
    label: "Daily Statement",
    path: "/profile/daily-statement",
    icon: "fa-chart-line",
    iconColor: "#0061bd",
  },
  {
    label: "Invite Friends",
    path: "/profile/invite-friends",
    icon: "fa-share-nodes",
    iconColor: "#237FFE",
  },

  {
    label: "Bumper Reward",
    path: "/profile/bumperRewards",
    icon: "fa-gift",
    iconColor: "#F4484C",
  },
  {
    label: "Turntable of Luck",
    path: "/profile/turntable",
    icon: "fa-files-pinwheel",
    iconColor: "red",
  },
  {
    label: "Help Book",
    path: "",
    icon: "fa-book-open",
    iconColor: "#FFAC3E",
  },
  // {
  //   label: "My Recharges",
  //   path: "/profile/recharges",
  // },
  // {
  //   label: "My Withdrawals",
  //   path: "/profile/withdrawals",
  // },
];

const profileKpiCars = [
  {
    label: "Wallet (INR)",
    name: "wallet_balance",
  },
  {
    label: "Plan Level",
    name: "plan",
  },
];

const profileDetailKpiCars = [
  {
    label: "Yesterday's earnings",
    name: "yesterday_earning",
    bgColorPrimaryColor: "#7e909a",
    bgColorSecondaryColor: "#262b2e",
  },
  {
    label: "Today's earnings",
    name: "today_earning",
    bgColorPrimaryColor: "#627980",
    bgColorSecondaryColor: "#1f3f49",
  },
  {
    label: "Total revenue",
    name: "total_revenue",
    bgColorPrimaryColor: "#e06c7a",
    bgColorSecondaryColor: "#d32d41",
  },
  {
    label: "Income this week",
    name: "income_this_week",
    bgColorPrimaryColor: "#6ab187",
    bgColorSecondaryColor: "#4a7c5f",
  },
  {
    label: "Complete the task today",
    name: "complete_the_task_today",
    bgColorPrimaryColor: "#488a99",
    bgColorSecondaryColor: "#2b535c",
  },
  {
    label: "Today's remaining tasks",
    name: "today_remaining_tasks",
    bgColorPrimaryColor: "#4cb5f5",
    bgColorSecondaryColor: "#2e6d93",
  },
  {
    label: "Monthly income",
    name: "monthly_income",
    bgColorPrimaryColor: "#ea6a47",
    bgColorSecondaryColor: "#8c402b",
  },
  {
    label: "Subordinate task rebates",
    name: "subordinate_task_rebates",
    bgColorPrimaryColor: "#cd8b83",
    bgColorSecondaryColor: "#ac3e31",
  },
  {
    label: "Recommended rebate",
    name: "recommended_rebate",
    bgColorPrimaryColor: "#797e8b",
    bgColorSecondaryColor: "#20283e",
  },
];
