import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CommonContext } from "../../App";
import { getCall, putCall } from "../../api/fetchConfig";
import { toast } from "react-toastify";
import { useFormik } from "formik";

const detailInputFields = [
  {
    name: "username",
    err: "User Name is required",
    label: "User Name",
  },
  {
    name: "Address",
    err: "Address is required",
    label: "Address",
  },
  { name: "email", err: "Email is required", label: "Email" },
];

// const detailValidate = (values) => {
//   const errors = {};

//   detailInputFields.forEach((item) => {
//     if (!values[item.name]) {
//       errors[item.name] = item.err;
//     }
//   });

//   return errors;
// };
const DetailedInfo = () => {
  const { userId } = useParams();
  const { setIsLoading, setUserDetails } = useContext(CommonContext);
  const [detailInfo, setDetailInfo] = useState({});
  const checkAdminRegex = /\badmin\b/g;
  const isAdmin = checkAdminRegex.test(window.location.pathname);

  useEffect(() => {
    if (userId) {
      fetchDetailInfo();
    }
  }, [userId]);
  const fetchDetailInfo = async () => {
    try {
      const url = `/api/${userId}/settings/personal-details`;
      setIsLoading(true);
      const response = await getCall(url);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setDetailInfo(data);
          setUserDetails((prev) => ({
            ...prev,
            username: data.username || "",
            Address: data.Address || "",
            email: data.email || "",
          }));
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Contact your administrator");
      console.error(error);
    }
  };

  const detailInfoFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      user_id: parseInt(userId || 0),
      username: detailInfo?.username || "",
      Address: detailInfo?.Address || "",
      email: detailInfo?.email || "",
    },
    onSubmit: async (values) => {
      try {
        const url = `/api/${userId}/settings/personal-details`;
        setIsLoading(true);
        const response = await putCall(url, values);
        if (response && response.status === 200) {
          const res = await response.json();

          if (!res || Object.keys(res).length === 0) {
            throw new Error("Empty response from server");
          }

          const { status_code, detail, error } = res;
          if (status_code === 200) {
            fetchDetailInfo();
            toast.success(detail);
          } else {
            toast.error(error);
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
        toast.error("Something went wrong. Contact your administrator");
        console.error(error);
      }
    },
    // validate: detailValidate,
    // validateOnChange: false,
    // validateOnBlur: false,
  });

  return (
    <form onSubmit={detailInfoFormik.handleSubmit} className="bankDetailsForm">
      <p className="contentTitle">Detailed Information</p>
      {detailInputFields.map((field) => {
        return (
          <div
            className="bankDetailsFormInputWrapper"
            key={`detail-info-form-field-${field.name}`}
          >
            <div>
              <label htmlFor={field.name}>{field.label}</label>
              <input
                name={field.name}
                onChange={detailInfoFormik.handleChange}
                value={detailInfoFormik?.values?.[field.name] || ""}
                id={field.name}
              />
            </div>
            <span className="errorText">
              {detailInfoFormik.errors[field.name]}
            </span>
          </div>
        );
      })}
      <button type="submit">Update</button>
    </form>
  );
};

export default DetailedInfo;
