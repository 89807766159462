import React, { useContext, useEffect, useState } from "react";
import { CommonContext } from "../../App";
import { getCall, putCall } from "../../api/fetchConfig";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";

const inputFields = [
  {
    name: "account_name",
    err: "Account Name is required",
    label: "Account Name",
  },
  {
    name: "account_no",
    err: "Account Number is required",
    label: "Account Number",
  },
  { name: "bank", err: "Bank Name is required", label: "Bank Name" },
  { name: "branch", err: "Branch is required", label: "Branch" },
  { name: "ifsc", err: "IFSC is required", label: "IFSC" },
  { name: "upi_id", err: "", label: "UPI ID" },
];

const validate = (values) => {
  const errors = {};

  inputFields.forEach((item) => {
    if (item.name !== "upi_id" && !values[item.name]) {
      errors[item.name] = item.err;
    }
  });

  return errors;
};

const BankDetails = () => {
  const { userId } = useParams();
  const { setIsLoading } = useContext(CommonContext);
  const [bankDetails, setBankDetails] = useState({});
  const checkAdminRegex = /\badmin\b/g;
  const isAdmin = checkAdminRegex.test(window.location.pathname);

  useEffect(() => {
    if (userId) {
      fetchBankDetails();
    }
  }, [userId]);

  const fetchBankDetails = async () => {
    try {
      const url = `/api/${userId}/settings/bank-details`;
      setIsLoading(true);
      const response = await getCall(url);
      if (response && response.status === 200) {
        const res = await response.json();
        const { status_code, data, error } = res;
        if (status_code === 200) {
          setBankDetails(data);
        } else {
          toast.error(error);
        }
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong. Contact your administrator");
      console.error(error);
    }
  };

  const bankDetailsFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...bankDetails,
    },
    onSubmit: async (values) => {
      try {
        const url = `/api/${userId}/settings/bank-details`;
        setIsLoading(true);
        const response = await putCall(url, values);
        if (response && response.status === 200) {
          const res = await response.json();

          if (!res || Object.keys(res).length === 0) {
            throw new Error("Empty response from server");
          }

          const { status_code, detail, error } = res;
          if (status_code === 200) {
            fetchBankDetails();
            toast.success(detail);
          } else {
            toast.error(error);
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
        toast.error("Something went wrong. Contact your administrator");
        console.error(error);
      }
    },
    validate,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <form onSubmit={bankDetailsFormik.handleSubmit} className="bankDetailsForm">
      <p className="contentTitle">Bank Details</p>
      {inputFields.map((field) => {
        return (
          <div
            className="bankDetailsFormInputWrapper"
            key={`bank-details-form-field-${field.name}`}
          >
            <div>
              <label htmlFor={field.name}>{field.label}</label>
              <input
                readOnly={!isAdmin && bankDetailsFormik?.values?.id}
                name={field.name}
                onChange={bankDetailsFormik.handleChange}
                value={bankDetailsFormik?.values?.[field.name] || ""}
                id={field.name}
              />
            </div>
            <span className="errorText">
              {bankDetailsFormik.errors[field.name]}
            </span>
          </div>
        );
      })}
      <button
        disabled={!isAdmin && bankDetailsFormik?.values?.id}
        type="submit"
      >
        Update
      </button>
    </form>
  );
};

export default BankDetails;
